import { useEntity } from '@backstage/plugin-catalog-react';
import { useMemo } from 'react';
import { KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION } from '@internal/plugin-cheetah-common';

export const useProducerTopicsForEntity = () => {
  const { entity } = useEntity();
  const annotation =
    entity.metadata.annotations?.[KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION] ?? '';

  const topicList = useMemo(() => {
    if (!annotation) return undefined;
    return annotation.split(',').map(producer => {
      const [clusterId, producerTopic] = producer.split('/');

      if (!clusterId || !producerTopic) {
        throw new Error(
          `Failed to parse kafka producer topics annotation: got "${annotation}"`,
        );
      }
      return {
        clusterId: clusterId.trim(),
        topic: producerTopic.trim(),
      };
    });
  }, [annotation]);

  return topicList;
};
