import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { costInsightsApiRef } from '@backstage-community/plugin-cost-insights';
import {
  CostInsightsClient,
  cheetahApiRef,
  CheetahBackendClient,
} from '@internal/plugin-cheetah';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

// apis is a list of APIs (mostly backend plugins) that are accessible from the frontend
export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: cheetahApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      new CheetahBackendClient({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {
      cheetahBackendApi: cheetahApiRef,
      catalogApi: catalogApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ cheetahBackendApi, catalogApi, identityApi }) =>
      new CostInsightsClient(cheetahBackendApi, catalogApi, identityApi),
  }),
];
