import {
  AvailableIssuesResponse,
  AvailablePackageVersionsResponse,
  AvailableRepositoriesResponse,
  AvailableSecurityAlertsResponse,
  DefectdojoVulnerabilitiesResponse,
  GithubLatestWorkflowsResponse,
  GitlabRepositoriesResponse,
  OpenCostSummaryStep,
} from '@internal/plugin-cheetah-common';
import { CheetahApi } from './types';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

export class CheetahBackendClient implements CheetahApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async internalGet(path: string, headers?: Headers): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('cheetah')}${path}`;

    const mergedHeaders = new Headers({
      'Content-Type': 'application/json',
    });
    if (headers) {
      headers.forEach((value, key) => {
        mergedHeaders.append(key, value);
      });
    }

    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: mergedHeaders,
    });

    if (!response.ok) {
      const payload = await response.text();
      throw new Error(
        `Request failed with ${response.status} ${response.statusText}, ${payload}`,
      );
    }

    return await response.json();
  }

  async getGithubRepositories(
    regexFilter: string,
    headers?: Headers,
  ): Promise<AvailableRepositoriesResponse> {
    return await this.internalGet(
      `/github-repos/${encodeURIComponent(regexFilter)}`,
      headers,
    );
  }

  async getGithubIssues(
    regexFilter: string,
    headers?: Headers,
  ): Promise<AvailableIssuesResponse> {
    return await this.internalGet(
      `/github-issues/${encodeURIComponent(regexFilter)}`,
      headers,
    );
  }

  async getDefectdojoVulnerabilities(
    severity: string,
    headers?: Headers | undefined,
  ): Promise<DefectdojoVulnerabilitiesResponse> {
    return await this.internalGet(
      `/defectdojo-vulnerabilities/${encodeURIComponent(severity)}`,
      headers,
    );
  }

  async getGithubSecurityAlerts(
    regexFilter: string,
    headers?: Headers | undefined,
  ): Promise<AvailableSecurityAlertsResponse> {
    return await this.internalGet(
      `/github-alerts/${encodeURIComponent(regexFilter)}`,
      headers,
    );
  }

  async getGithubLatestWorkflows(
    regexFilter: string,
    headers?: Headers | undefined,
  ): Promise<GithubLatestWorkflowsResponse> {
    return await this.internalGet(
      `/github-workflow-runs/${encodeURIComponent(regexFilter)}`,
      headers,
    );
  }

  async getGitlabRepositories(
    regexFilter: string,
    headers?: Headers,
  ): Promise<GitlabRepositoriesResponse> {
    return await this.internalGet(
      `/gitlab-repos/${encodeURIComponent(regexFilter)}`,
      headers,
    );
  }

  async getKubernetesNameSpaces(): Promise<string[]> {
    return await this.internalGet(`/kubernetes/namespaces`);
  }

  async getKubernetesPodIds(): Promise<string[]> {
    return await this.internalGet(`/kubernetes/podIds`);
  }

  async getGithubImageTags(props: {
    org: string;
    package_name: string;
    headers?: Headers;
  }): Promise<AvailablePackageVersionsResponse> {
    const { org, package_name, headers } = props;

    return await this.internalGet(
      `/github-packages/${org}/${package_name}`,
      headers,
    );
  }

  async getComputeAllocation(
    aggregate: string,
    window: string,
    accumulate?: boolean,
    headers?: Headers,
  ): Promise<OpenCostSummaryStep[]> {
    return await this.internalGet(
      `/allocation/compute/?aggregate=${aggregate}&window=${window}&step=1d${
        accumulate ? '&accumulate=true' : ''
      }`,
      headers,
    );
  }
}
