import { Select } from '@backstage/core-components';
import { Direction } from '@backstage/plugin-catalog-graph';
import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';

const DIRECTION_DISPLAY_NAMES = {
  [Direction.LEFT_RIGHT]: 'Left to right',
  [Direction.RIGHT_LEFT]: 'Right to left',
  [Direction.TOP_BOTTOM]: 'Top to bottom',
  [Direction.BOTTOM_TOP]: 'Bottom to top',
};

export type Props = {
  value: Direction;
  onChange: (value: Direction) => void;
};

export const DirectionFilter = ({ value, onChange }: Props) => {
  const handleChange = useCallback(
    (v: string | string[] | number | number[]) => onChange(v as Direction),
    [onChange],
  );

  return (
    <Box pb={1} pt={1}>
      <Select
        label="Direction"
        selected={value}
        items={Object.values(Direction).map(v => ({
          label: DIRECTION_DISPLAY_NAMES[v],
          value: v,
        }))}
        onChange={handleChange}
      />
    </Box>
  );
};
