/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectItem } from '@backstage/core-components';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getRepoNameBasedOnTeamName } from './utils';
import { RepoUrlPickerState } from './types';
import { LinearProgress } from '@material-ui/core';

export const RepoUrlPickerRepoName = (props: {
  state: RepoUrlPickerState;
  team: string;
  loading: boolean;
  availableRepos: string[];
  onChange: (repo: string) => void;
  rawErrors: string[];
}) => {
  const { state, team, onChange, rawErrors, loading, availableRepos } = props;
  const [teamRepoName, setTeamRepoName] = useState<string>('');

  useEffect(() => {
    setTeamRepoName(getRepoNameBasedOnTeamName(availableRepos, team, state));
    onChange(teamRepoName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const repoItems: SelectItem[] = availableRepos
    ? availableRepos.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  if (!teamRepoName) {
    return <LinearProgress />;
  }
  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !state.repoName}
      >
        <Select
          native
          label="Available Repositories"
          onChange={selected =>
            onChange(String(Array.isArray(selected) ? selected[0] : selected))
          }
          disabled={loading || availableRepos?.length === 1}
          selected={teamRepoName}
          items={repoItems}
        />
        <FormHelperText>The name of the repository</FormHelperText>
      </FormControl>
    </>
  );
};
