import { Entity } from '@backstage/catalog-model';
import {
  KUBERNETES_INSTANCE_ANNOTATION,
  KUBERNETES_NAMESPACE_ANNOTATION,
  OpenCostSummary,
} from '@internal/plugin-cheetah-common';
import _ from 'lodash';

export function openCostSummaryMatchesEntity(
  openCostSummary: OpenCostSummary,
  entity: Entity,
) {
  const entityNamespace =
    entity.metadata.annotations?.[KUBERNETES_NAMESPACE_ANNOTATION];
  const entityInstanceId =
    entity.metadata.annotations?.[KUBERNETES_INSTANCE_ANNOTATION];
  const openCostInstanceId =
    openCostSummary.properties.labels?.[
      _.snakeCase(KUBERNETES_INSTANCE_ANNOTATION)
    ];

  if (entityNamespace && entityInstanceId) {
    // TODO: perhaps we should in gui that we can not display cost if namespace is not set
    if (openCostInstanceId) {
      return (
        openCostSummary.properties.namespace === entityNamespace &&
        openCostInstanceId === entityInstanceId
      );
    }

    return (
      openCostSummary.properties.namespace === entityNamespace &&
      openCostSummary.properties.pod.startsWith(entityInstanceId) // best effort, as label is missing in opencost for some reason
    );
  }
  return false;
}
