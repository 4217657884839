/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { Route, Routes } from 'react-router';
import {
  MissingAnnotationEmptyState,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  KAFKA_CONSUMER_GROUP_ANNOTATION,
  KAFKA_PRODUCER_INPUTTOPICS_ANNOTATION,
  KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION,
} from '@internal/plugin-cheetah-common';
import { KafkaTopicsForConsumer } from './components/KafkaTopicsForConsumer';

export const hasConsumerGroupsAnnotation = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[KAFKA_CONSUMER_GROUP_ANNOTATION]);

export const hasOutputTopicsAnnotation = (entity: Entity) =>
  Boolean(
    entity.metadata.annotations?.[KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION],
  );

export const hasInputTopicsAnnotation = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[KAFKA_PRODUCER_INPUTTOPICS_ANNOTATION]);

export const isPluginApplicableToEntity = (entity: Entity) => {
  return (
    hasOutputTopicsAnnotation(entity) || hasConsumerGroupsAnnotation(entity)
  );
};

export const isKafkaTopicsAvailableToEntity = (entity: Entity) => {
  return (
    hasOutputTopicsAnnotation(entity) ||
    hasConsumerGroupsAnnotation(entity) ||
    hasInputTopicsAnnotation(entity)
  );
};

type Props = {};

export const Router = (_props: Props) => {
  const { entity } = useEntity();

  if (!isPluginApplicableToEntity(entity)) {
    return (
      <>
        <MissingAnnotationEmptyState
          annotation={KAFKA_CONSUMER_GROUP_ANNOTATION}
        />
        <MissingAnnotationEmptyState
          annotation={KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION}
        />
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<KafkaTopicsForConsumer />} />
    </Routes>
  );
};
