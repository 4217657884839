/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 700 700"
    >
      <title>logo-backstage-white</title>
      <g
        transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M622 4831 l0 -459 22 -4 c11 -2 391 -3 844 -3 l822 1 1 -20 c1 -12 2
-636 3 -1388 0 -751 5 -1370 9 -1375 5 -4 276 -8 602 -8 l593 0 0 1380 c1 759
1 1387 1 1395 1 14 93 15 834 15 457 -1 838 1 845 3 9 3 12 105 12 463 l0 459
-2294 0 -2294 0 0 -459z"
        />
        <path
          d="M5286 2580 c-3 -5 0 -1031 2 -1040 1 -3 256 -5 567 -5 l565 0 1 25
c4 135 0 1012 -5 1017 -7 7 -1127 10 -1130 3z"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
