import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { cheetahApiRef } from '../api/types';

export const useGitlabRepositories = (pattern: string) => {
  const cheetahBackend = useApi(cheetahApiRef);
  const errorApi = useApi(errorApiRef);

  const {
    loading,
    value: availableRepos,
    retry,
  } = useAsyncRetry(async () => {
    return cheetahBackend
      .getGitlabRepositories(pattern)
      .then(result => result.repos)
      .catch(err => {
        errorApi.post(err);
        throw err;
      });
  });

  return [{ loading, availableRepos }, { retry }] as const;
};
