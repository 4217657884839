import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';

import {
  ScaffolderLayouts,
  ScaffolderFieldExtensions,
} from '@backstage/plugin-scaffolder-react';

import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  IdentityProviders,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { CatalogGraphPage, Direction } from '@backstage/plugin-catalog-graph';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CheetahCatalogGraphPage,
  CheetahCatalogPage,
  CheetahImageTagPickerExtension,
  CheetahRepoUrlPickerExtension,
  PreviewValueFieldFieldExtension,
  CheetahKubernetesVariablePickerExtension,
  SelectFieldFromApiExtension,
  ShowDiagramLayout,
  StandardAppsPage,
} from '@internal/plugin-cheetah';
import {
  RELATION_OWNER_OF,
  RELATION_OWNED_BY,
  RELATION_CONSUMES_API,
  RELATION_API_CONSUMED_BY,
  RELATION_PROVIDES_API,
  RELATION_API_PROVIDED_BY,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
} from '@backstage/catalog-model';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { CostInsightsPage } from '@backstage-community/plugin-cost-insights';
import {
  configApiRef,
  githubAuthApiRef,
  gitlabAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import StorageIcon from '@material-ui/icons/Storage';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ShareIcon from '@material-ui/icons/Share';
import {
  CHEETAH_CUSTOM_TYPES_TOPIC,
  CHEETAH_CUSTOM_TYPES_FLINK,
} from '@internal/plugin-cheetah-common';
import { Config } from '@backstage/config';
import { KamstrupIcon } from './assets/customIcons';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { settingsPage } from './components/user-settings/SettingsPage';

const app = createApp({
  apis,
  icons: {
    [`kind:${CHEETAH_CUSTOM_TYPES_TOPIC}`]: StorageIcon,
    [`kind:platform`]: DeviceHubIcon,
    [`kind:${CHEETAH_CUSTOM_TYPES_FLINK}`]: ShareIcon,
    ['logo:Kamstrup']: KamstrupIcon,
  },
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      const allowGuestAccess =
        configApi.getOptionalBoolean('auth.allowGuestAccess') || false;

      const providers = [] as IdentityProviders;

      if (providerIsSupported(configApi, 'github')) {
        providers.push({
          id: 'github-auth-provider',
          title: 'GitHub',
          message: 'Sign in using GitHub',
          apiRef: githubAuthApiRef,
        });
      }
      if (providerIsSupported(configApi, 'gitlab')) {
        providers.push({
          id: 'gitlab-auth-provider',
          title: 'Gitlab',
          message: 'Sign in using Gitlab',
          apiRef: gitlabAuthApiRef,
        });
      }

      if (allowGuestAccess || !providers.length) {
        providers.push('guest');
      }
      return <SignInPage {...props} auto providers={providers} />;
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

// https://backstage.io/docs/features/software-catalog/catalog-customization
const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    {/*  <Route path="/catalog" element={<CatalogIndexPage />}>
      <CheetahCatalogPage />
    </Route> */}
    <Route path="/systems" element={<CatalogIndexPage />}>
      <CheetahCatalogPage initialKind="system" />
    </Route>
    <Route path="/resources" element={<CatalogIndexPage />}>
      <CheetahCatalogPage initialKind="resource" />
    </Route>
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route path="/infrastructure" element={<CatalogIndexPage />}>
      <StandardAppsPage
        description="Platform components used by software components"
        pageTitle="Platform components"
        hideInfrastructure={false}
      />
    </Route>

    <Route path="/standard-apps" element={<CatalogIndexPage />}>
      <StandardAppsPage
        pageTitle="Standard apps"
        description="Standard applications available to be deployed together with other components in a system"
        hideInfrastructure
      />
    </Route>

    <Route path="/deployed" element={<CatalogIndexPage />}>
      <CheetahCatalogPage />
    </Route>

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          contextMenu={{
            editor: process.env.NODE_ENV?.toLowerCase() !== 'production',
            actions: process.env.NODE_ENV?.toLowerCase() !== 'production',
          }}
          headerOptions={{
            pageTitleOverride: 'Create and deploy software',
            title: 'Create and deploy software',
            subtitle: 'Create and deploy software using template actions',
          }}
          groups={[
            {
              title: 'Getting started guide',
              filter: entity =>
                entity?.metadata?.tags?.includes('getting-started-guide') ??
                false,
            },
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderLayouts>
        <ShowDiagramLayout />
      </ScaffolderLayouts>
      <ScaffolderFieldExtensions>
        <SelectFieldFromApiExtension />
        <PreviewValueFieldFieldExtension />
        <CheetahRepoUrlPickerExtension />
        <CheetahKubernetesVariablePickerExtension />
        <CheetahImageTagPickerExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    <Route path="/cheetah-catalog" element={<CheetahCatalogGraphPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            // rootEntityRefs: ['component:default/alertservice'],
            selectedKinds: [
              'component',
              'system',
              'api',
              'group',
              'platform',
              'resource',
            ],
            maxDepth: 3,
            unidirectional: false,
            mergeRelations: true,
            direction: Direction.LEFT_RIGHT,
            showFilters: true,
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
  </FlatRoutes>
);
export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);

function providerIsSupported(configApi: Config, providerId: string) {
  const providersConfig = configApi.getConfig('auth.providers');
  const configuredProviders = providersConfig.keys();

  const environment = configApi.getString('auth.environment');

  return Boolean(
    configuredProviders.includes(providerId) &&
      providersConfig.getOptionalString(
        `${providerId}.${environment}.clientId`,
      ),
  );
}
