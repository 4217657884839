import React, { useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { useEntityRelationGraph } from './internal/useEntityRelationGraph';
import _ from 'lodash';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { getRelationDisplayText, normalizeEntityRefName } from './utils';
import {
  CHEETAH_CUSTOM_TYPES_FLINK,
  CHEETAH_CUSTOM_TYPES_INFRASTRUCTURE,
  CHEETAH_CUSTOM_TYPES_TOPIC,
  ALL_CHEETAH_RELATION_PAIRS,
} from '@internal/plugin-cheetah-common';

export const ExportToPlantuml = (props: {
  rootEntityNames: CompoundEntityRef | CompoundEntityRef[];
  maxDepth?: number;
  kinds?: string[];
  relations?: string[];
  skipRoot?: boolean;
  mergeRelations?: boolean;
}) => {
  const {
    rootEntityNames,
    maxDepth = 2,
    kinds,
    relations,
    skipRoot = true,
    mergeRelations = true,
  } = props;
  const rootEntityRefs = useMemo(
    () =>
      (Array.isArray(rootEntityNames)
        ? rootEntityNames
        : [rootEntityNames]
      ).map(e => stringifyEntityRef(e)),
    [rootEntityNames],
  );

  const { entities, loading, error } = useEntityRelationGraph({
    rootEntityRefs,
    filter: {
      maxDepth,
      kinds,
      relations,
    },
  });

  if (loading || !entities || Object.keys(entities).length === 0) {
    return <Progress />;
  }

  if (error) {
    return <ErrorPanel error={error} />;
  }

  const downloadTxtFile = () => {
    const componentsTexts = [] as string[];
    const relationsTexts = [] as string[];

    const visitedNodes = new Set<string>();
    const nodeQueue = [...rootEntityRefs];

    while (nodeQueue.length > 0) {
      const entityRef = nodeQueue.pop()!;
      const entity = entities[entityRef];
      visitedNodes.add(entityRef);

      if (!entity) continue;
      const isRoot = rootEntityRefs.includes(entityRef);

      let elementType = 'component';
      if (entity?.spec?.type === CHEETAH_CUSTOM_TYPES_FLINK) {
        elementType = 'control';
      }
      if (entity?.spec?.type === CHEETAH_CUSTOM_TYPES_TOPIC) {
        elementType = 'queue';
      }
      if (entity?.spec?.type === CHEETAH_CUSTOM_TYPES_INFRASTRUCTURE) {
        elementType = 'database';
      }
      if (entity?.kind === 'API') {
        elementType = 'boundary';
      }

      const shouldPrint = !isRoot || !skipRoot;

      if (shouldPrint) {
        componentsTexts.push(
          `${elementType} "${entity.metadata.name}" as ${normalizeEntityRefName(
            entityRef,
          )}\r\n`,
        );
      }

      entity?.relations?.forEach(rel => {
        // Check if the related entity should be displayed, if not, ignore
        // the relation too
        if (!entities[rel.targetRef]) {
          return;
        }

        if (relations && !relations.includes(rel.type)) {
          return;
        }

        if (
          kinds &&
          !kinds.some(kind =>
            rel.targetRef.startsWith(`${kind.toLocaleLowerCase('en-US')}:`),
          )
        ) {
          return;
        }
        if (shouldPrint) {
          const pair = ALL_CHEETAH_RELATION_PAIRS.find(
            ([l, r]) => l === rel.type || r === rel.type,
          ) ?? [rel.type];
          const [left] = pair;

          const normalizedTargetRefName = normalizeEntityRefName(rel.targetRef);
          const normalizedEntityRefName = normalizeEntityRefName(entityRef);
          const relationDescription = getRelationDisplayText(rel.type, ': ');

          const isTopic = entity.spec?.type === CHEETAH_CUSTOM_TYPES_TOPIC;

          const isInfrastructure =
            entity.spec?.type === CHEETAH_CUSTOM_TYPES_INFRASTRUCTURE;

          const dependentOf = left === rel.type;

          if (isTopic || isInfrastructure) {
            if (dependentOf) {
              relationsTexts.push(
                `${normalizedEntityRefName} <-- ${normalizedTargetRefName}${relationDescription}\r\n`,
              );
            }
          } else if (dependentOf) {
            relationsTexts.push(
              `${normalizedEntityRefName} --> ${normalizedTargetRefName}${relationDescription}\r\n`,
            );
          } else if (!mergeRelations) {
            relationsTexts.push(
              `${normalizedTargetRefName} <-- ${normalizedEntityRefName}${relationDescription}\r\n`,
            );
          }
        }
        if (!visitedNodes.has(rel.targetRef)) {
          nodeQueue.push(rel.targetRef);
          visitedNodes.add(rel.targetRef);
        }
      });
    }

    const texts = [
      '@startuml\r\n',
      ...componentsTexts,
      ...relationsTexts,
      '@enduml',
    ];

    // file object
    const file = new Blob(texts, { type: 'text/plain' });
    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${humanizeEntityRef(
      (Array.isArray(rootEntityNames) ? rootEntityNames : [rootEntityNames])[0],
    )}-${Date.now()}.txt`;
    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Grid>
      <Button variant="contained" color="primary" onClick={downloadTxtFile}>
        Export To PlantUML
      </Button>
    </Grid>
  );
};
