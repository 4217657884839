import { JsonValue } from '@backstage/types';
import _ from 'lodash';

export const additionalTemplateFilters = {
  /**
   * Returns input in kebab-case
   */
  kebabCase: (input: string) => {
    return _.kebabCase(input);
  },
  /**
   * UniqueJobName
   * @param args team name
   * @returns  job name as kebabcase and team
   */
  uniqueJobName: (...args: JsonValue[]) => {
    const input = args[0] as string;
    if (!input) return '';
    const team = (args[1] as string) || '';
    const kebabName = _.kebabCase(input);
    if (team) {
      return `${team}-${kebabName}`;
    }
    return kebabName;
  },
  /**
   * Gets the last value based on seperator (default .)
   */
  lastValue: (...args: JsonValue[]) => {
    const input = args[0] as string;
    if (!input) return '';
    const seperator = (args[1] as string) || '.';
    return input.split(seperator).pop()!;
  },
  /**
   * Returns the system name WITHOUT the team prefix (if present)
   * also enforces lowercase and trims
   */
  systemName: (...args: JsonValue[]) => {
    const system = args[0] as string;
    if (!system) return '';
    const team = (args[1] as string) || '';
    return (
      system
        // remove team
        .replace(team, '')
        .replace('-team', '')
        // trim whitespace and -
        .replace(/\s/g, '')
        .replace(/^(-)+|(-)+$/g, '') // remove any leading or trailing - seperators
        .toLowerCase()
    );
  },
  /**
   * remove special+whitespace characters
   * enforce lowercase and remove "-team" suffixes (if present)
   */
  teamName: (input: string) => {
    return input
      .replace(/group:.*?\//, '') // remove group
      .replace(/(-team)+$/, '')
      .replace(/\s/g, '') // remove whitespace
      .toLowerCase()
      .trim();
  },
  /**
   * Replace all '-team' suffixes with a single '-team'
   */
  repoName: (input: string) => {
    return input.toLowerCase().replace(/(-team)+$/, '-team');
  },
  /**
   * Takes the repo url generated på RepoUrlPicker and extracts the repo path.
   * E.g. gitlab.com?owner=trifork&repo=kubernetes%2Fdev%2Ftest-app -> kubernetes/dev/test-app
   */
  extractRepoPath: (input: string) => {
    return input.split('repo=')[1].replaceAll('%2F', '/');
  },
  /**
   * Replace all '.' with a '-'
   */
  replaceDotWithDash: (input: string) => {
    return input.replaceAll('.', '-');
  },
  /**
   * Convert gb to bytes
   */
  gbToBytes: (input: number) => {
    if (input <= 0) return -1;

    return input * 1073741824;
  },
  /**
   * Convert days to milliseconds
   */
  daysToMilliseconds: (input: number) => {
    if (input <= 0) return -1;

    return input * 86400000;
  },
} as Record<string, any>;
