import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { CheetahKubernetesVariablesPicker } from './CheetahKubernetesVariablePicker';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export const CheetahKubernetesVariablePickerExtension =
  scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'CheetahKubernetesVariablesPicker',
      component: CheetahKubernetesVariablesPicker,
    }),
  );
