import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  ErrorPanel,
  Progress,
  Select,
  SelectItem,
} from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { get } from 'lodash';
import lodash from 'lodash';
import { selectFieldFromApiConfigSchema } from './types';
import { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const SelectFieldFromApi = ({
  onChange,
  required,
  uiSchema,
  rawErrors,
  formData,
  formContext,
}: FieldExtensionComponentProps<string, {}>): React.ReactElement => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const [dropDownData, setDropDownData] = useState<SelectItem[] | undefined>();
  const [selected, setSelected] = useState<string | undefined>();

  const { error } = useAsync(async () => {
    const baseUrl = await discoveryApi.getBaseUrl('');
    const options = selectFieldFromApiConfigSchema.parse(
      uiSchema!['ui:options'],
    );
    const params = new URLSearchParams(options.params);
    const response = await fetchApi.fetch(
      `${baseUrl}${options.path}?${params}`,
    );
    const body = await response.json();
    let array = body;
    if (options.arraySelector) {
      array = get(body, options.arraySelector);
    } else {
      array = body as unknown[];
    }

    const localDropDownData = lodash
      .chain(array)
      .map((item: unknown) => {
        let value: string | undefined;
        let label: string | undefined;

        if (options.valueSelector) {
          value = get(item, options.valueSelector);
          label = options.labelSelector
            ? get(item, options.labelSelector)
            : value;
        } else {
          if (!(typeof item === 'string')) {
            throw new Error(
              `The item provided for the select drop down "${item}" is not a string`,
            );
          }
          value = item;
          label = item;
        }

        if (!value) {
          throw new Error(`Failed to populate SelectFieldFromApi dropdown`);
        }

        return {
          value,
          label: label || value,
        };
      })
      .filter(item => {
        // Custom
        if (options.filter) {
          return new RegExp(options.filter, 'i').test(item.value);
        }
        return true;
      })
      .value();
    setDropDownData(localDropDownData);
    if (options.defaultFromParameterValue) {
      const formRecords: Record<string, any> = formContext.formData;
      const fieldValue = lodash.find(formRecords, (_, fieldName) => {
        return fieldName === options.defaultFromParameterValue;
      });
      if (fieldValue) {
        setSelected(
          lodash.find(localDropDownData, d => d.value.includes(fieldValue))
            ?.value,
        ); // try set first match, if any
      }
    }
  });

  if (error) {
    return <ErrorPanel error={error} />;
  }

  if (!dropDownData) {
    return <Progress />;
  }

  if (selected) {
    onChange(selected);
  }

  const formError: boolean = (rawErrors?.length && !formData) || false;

  return (
    <FormControl margin="normal" required={required} error={formError}>
      <Select
        items={dropDownData}
        selected={selected}
        label="Select"
        onChange={value => {
          const valueString = String(value);
          setSelected(valueString);
          onChange(valueString);
        }}
      />
    </FormControl>
  );
};

export const selectFieldFromApiValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (
    value &&
    value.length < 2 // || !KubernetesValidatorFunctions.isValidObjectName(value)
  ) {
    validation.addError(
      'must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
};
