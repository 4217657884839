/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity, RELATION_PART_OF } from '@backstage/catalog-model';
import {
  useEntityList,
  getEntityRelations,
  humanizeEntityRef,
  DefaultEntityFilters,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EntitySystemFilter } from '../../filters';

/** @public */
export type CatalogReactEntitySystemPickerClassKey = 'input';

const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactEntitySystemPicker',
  },
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type CustomFilters = DefaultEntityFilters & {
  systems?: EntitySystemFilter;
};

/** @public */
export const EntitySystemPicker = () => {
  const classes = useStyles();
  const {
    updateFilters,
    backendEntities,
    filters,
    queryParameters: { systems: systemsParameter },
  } = useEntityList<CustomFilters>();

  const queryParamSystems = useMemo(
    () => [systemsParameter].flat().filter(Boolean) as string[],
    [systemsParameter],
  );

  const [selectedSystems, setSelectedSystems] = useState(
    queryParamSystems.length
      ? queryParamSystems
      : filters.systems?.values ?? [],
  );

  // Set selected systems on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamSystems.length) {
      setSelectedSystems(queryParamSystems);
    }
  }, [queryParamSystems]);

  useEffect(() => {
    updateFilters({
      systems: selectedSystems.length
        ? new EntitySystemFilter(selectedSystems)
        : undefined,
    });
  }, [selectedSystems, updateFilters]);

  const handleChange = useCallback((_: unknown, value: string[]) => {
    setSelectedSystems(value);
  }, []);

  const availableSystemers = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .flatMap((e: Entity) =>
              getEntityRelations(e, RELATION_PART_OF).map(o =>
                humanizeEntityRef(o, { defaultKind: 'system' }),
              ),
            )
            .filter(Boolean) as string[],
        ),
      ].sort(),
    [backendEntities],
  );

  if (!availableSystemers.length) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label">
        System
        <Autocomplete
          multiple
          options={availableSystemers}
          value={selectedSystems}
          onChange={handleChange}
          renderOption={(option, { selected }) => (
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              }
              label={option}
            />
          )}
          size="small"
          popupIcon={<ExpandMoreIcon data-testid="system-picker-expand" />}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              variant="outlined"
            />
          )}
        />
      </Typography>
    </Box>
  );
};
