import { Entity } from '@backstage/catalog-model';
import _ from 'lodash';
import { KafkaDashboardApi } from './types';

export class CheetahKafkaDashboardClient {
  private readonly kafkaDashboardApi: KafkaDashboardApi;

  constructor(options: { kafkaDashboardApi: KafkaDashboardApi }) {
    this.kafkaDashboardApi = options.kafkaDashboardApi;
  }

  hasDashboardUrl(
    clusterId: string,
    consumerGroup: string,
    entity: Entity,
  ): boolean {
    const dashboardUrl = this.kafkaDashboardApi.getDashboardUrl(
      clusterId,
      consumerGroup,
      entity,
    );
    return Boolean(dashboardUrl.url);
  }

  getConsumerGroupUrl(
    clusterId: string,
    consumerGroup: string,
    entity: Entity,
  ): { url?: string } {
    const dashboardUrl = this.kafkaDashboardApi.getDashboardUrl(
      clusterId,
      consumerGroup,
      entity,
    );

    if (!dashboardUrl.url) {
      return { url: undefined };
    }
    if (dashboardUrl.isEntityUrl) {
      return {
        url: dashboardUrl.url,
      };
    }
    return {
      url: `${_.trimEnd(dashboardUrl.url, '/')}/groups/${consumerGroup}`,
    };
  }

  getTopicUrl(
    clusterId: string,
    consumerGroup: string,
    entity: Entity,
    topicName: string,
  ): { url?: string } {
    const dashboardUrl = this.kafkaDashboardApi.getDashboardUrl(
      clusterId,
      consumerGroup,
      entity,
    );

    if (!dashboardUrl.url) {
      return { url: undefined };
    }

    if (dashboardUrl.isEntityUrl) {
      return {
        url: dashboardUrl.url,
      };
    }
    return {
      url: `${_.trimEnd(dashboardUrl.url, '/')}/topics/${topicName}`,
    };
  }
}
