// https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/CatalogPage/DefaultCatalogPage.tsx
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListFilterKind,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { CatalogTable, CatalogTableRow } from '../CatalogTable';
import { CatalogKindHeader } from '../CatalogKindHeader';
import { EntitySystemPicker } from '../EntitySystemPicker';
import { CheetahEntityInfrastructureFilter } from '../CheetahEntityInfrastructureFilter';

/**
 * Props for root catalog pages.
 *
 * @public
 */
export interface CheetahCatalogPageProps {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  initialKind?: string;
  hideInfrastructure?: boolean;
  tableOptions?: TableProps<CatalogTableRow>['options'];
}

export function CheetahCatalogPage(props: CheetahCatalogPageProps) {
  const {
    columns,
    actions,
    initiallySelectedFilter = 'owned',
    initialKind = 'component',
    hideInfrastructure,
    tableOptions = {},
  } = props;
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  return (
    <PageWithHeader title={`${orgName} Catalog`} themeId="home">
      <EntityListProvider>
        <Content>
          <ContentHeader
            titleComponent={<CatalogKindHeader initialFilter={initialKind} />}
          >
            <SupportButton>Support pages</SupportButton>
          </ContentHeader>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityTypePicker />
              {/* <CheetahEntityTypePicker
                hiddenTypes={['infrastructure']}
                initialFilter={initialType}
              /> */}
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityOwnerPicker />
              {/* Custom */}
              <EntitySystemPicker />
              <CheetahEntityInfrastructureFilter
                hideInfrastructure={hideInfrastructure}
              />
              {/* end Custom */}
              <EntityLifecyclePicker />
              <EntityTagPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable
                columns={columns}
                actions={actions}
                tableOptions={tableOptions}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
}
