import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  SelectFieldFromApi,
  selectFieldFromApiValidation,
} from './SelectFieldFromApi';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export const SelectFieldFromApiExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'cheetah:SelectFieldFromApi',
    component: SelectFieldFromApi,
    validation: selectFieldFromApiValidation,
  }),
);
