// https://github.com/backstage/backstage/blob/master/plugins/catalog-graph/src/components/CatalogGraphPage/CatalogGraphPage.tsx

import {
  CompoundEntityRef,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
// eslint-disable-next-line @backstage/no-undeclared-imports
import { useLocation } from 'react-router';
import { Direction } from '@backstage/plugin-catalog-graph';

export type CheetahCatalogGraphPageValue = {
  rootEntityNames: CompoundEntityRef[];
  setRootEntityNames: Dispatch<React.SetStateAction<CompoundEntityRef[]>>;
  maxDepth: number;
  setMaxDepth: Dispatch<React.SetStateAction<number>>;
  direction: Direction;
  setDirection: Dispatch<React.SetStateAction<Direction>>;
  unidirectional: boolean;
  setUnidirectional: Dispatch<React.SetStateAction<boolean>>;
  mergeRelations: boolean;
  setMergeRelations: Dispatch<React.SetStateAction<boolean>>;
};

export function useCheetahCatalogGraphPage({
  initialState = {},
}: {
  initialState?: {
    rootEntityRefs?: string[];
    maxDepth?: number;
    direction?: Direction;
    unidirectional?: boolean;
    mergeRelations?: boolean;
  };
}): CheetahCatalogGraphPageValue {
  const location = useLocation();
  const query = useMemo(
    () =>
      (qs.parse(location.search, { arrayLimit: 0, ignoreQueryPrefix: true }) ||
        {}) as {
        rootEntityRefs?: string[] | string;
        maxDepth?: string[] | string;
        direction?: string[] | Direction;
        unidirectional?: string[] | string;
        mergeRelations?: string[] | string;
      },
    [location.search],
  );

  // Initial state
  const [rootEntityNames, setRootEntityNames] = useState<CompoundEntityRef[]>(
    () =>
      (Array.isArray(query.rootEntityRefs)
        ? query.rootEntityRefs
        : initialState?.rootEntityRefs ?? []
      ).map(r => parseEntityRef(r)),
  );
  const [maxDepth, setMaxDepth] = useState<number>(() =>
    typeof query.maxDepth === 'string'
      ? parseMaxDepth(query.maxDepth)
      : initialState?.maxDepth ?? Number.POSITIVE_INFINITY,
  );
  const [direction, setDirection] = useState<Direction>(() =>
    typeof query.direction === 'string'
      ? query.direction
      : initialState?.direction ?? Direction.LEFT_RIGHT,
  );

  const [unidirectional, setUnidirectional] = useState<boolean>(() =>
    typeof query.unidirectional === 'string'
      ? query.unidirectional === 'true'
      : initialState?.unidirectional ?? true,
  );
  const [mergeRelations, setMergeRelations] = useState<boolean>(() =>
    typeof query.mergeRelations === 'string'
      ? query.mergeRelations === 'true'
      : initialState?.mergeRelations ?? true,
  );

  // Update from query parameters
  useEffect(() => {
    if (Array.isArray(query.rootEntityRefs)) {
      setRootEntityNames(query.rootEntityRefs.map(r => parseEntityRef(r)));
    }
    if (typeof query.maxDepth === 'string') {
      setMaxDepth(parseMaxDepth(query.maxDepth));
    }
    if (typeof query.unidirectional === 'string') {
      setUnidirectional(query.unidirectional === 'true');
    }

    if (typeof query.mergeRelations === 'string') {
      setMergeRelations(query.mergeRelations === 'true');
    }

    if (typeof query.direction === 'string') {
      setDirection(query.direction);
    }
  }, [
    query,
    setRootEntityNames,
    setMergeRelations,
    setUnidirectional,
    setDirection,
    setMaxDepth,
  ]);

  // Update query parameters
  useEffect(() => {
    const rootEntityRefs = rootEntityNames.map(e => stringifyEntityRef(e));
    const newParams = qs.stringify(
      {
        rootEntityRefs,
        direction,
        unidirectional,
        maxDepth,
        mergeRelations,
      },
      { arrayFormat: 'brackets', addQueryPrefix: true },
    );
    const newUrl = `${window.location.pathname}${newParams}`;

    window.history.pushState(null, document.title, newUrl);
  }, [rootEntityNames, unidirectional, direction, maxDepth, mergeRelations]);

  return {
    rootEntityNames,
    setRootEntityNames,
    direction,
    setDirection,
    maxDepth,
    setMaxDepth,
    unidirectional,
    setUnidirectional,
    mergeRelations,
    setMergeRelations,
  };
}

function parseMaxDepth(value: string): number {
  return value === '∞' ? Number.POSITIVE_INFINITY : Number(value);
}
