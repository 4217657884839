import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { RepoUrlPicker } from './RepoUrlPicker';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export const CheetahRepoUrlPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CheetahRepoUrlPicker',
    component: RepoUrlPicker,
  }),
);
