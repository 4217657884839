import {
  DefaultEntityFilters,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { useEffect } from 'react';
import { EntityCheetahInfrastructureFilter } from '../../filters';

export type CheetahEntityFilters = DefaultEntityFilters & {
  infrastructureFilter?: EntityCheetahInfrastructureFilter;
};

/**
 * Props for {@link CheetahEntityInfrastructureFilter}.
 *
 * @public
 */
export interface CheetahEntityInfrastructureFilterProps {
  hideInfrastructure?: boolean;
}

/**
 * Filter with no UI that either shows all either or only infrastructure entities.
 *
 * @public
 */
export const CheetahEntityInfrastructureFilter = (
  props: CheetahEntityInfrastructureFilterProps,
) => {
  let { hideInfrastructure } = props;
  const { updateFilters, filters } = useEntityList<CheetahEntityFilters>();

  if (hideInfrastructure === undefined) {
    const catalogFilterForType = filters.type?.getCatalogFilters();
    hideInfrastructure =
      !catalogFilterForType?.['spec.type'].includes('infrastructure');
  }

  useEffect(() => {
    updateFilters({
      infrastructureFilter: new EntityCheetahInfrastructureFilter(
        hideInfrastructure!,
      ),
    });
  }, [updateFilters, hideInfrastructure]);

  return null;
};
