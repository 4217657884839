import React from 'react';
import { Grid } from '@material-ui/core';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Content,
  ErrorPanel,
  Header,
  HeaderLabel,
  Page,
  Progress,
} from '@backstage/core-components';
import useAsync from 'react-use/esm/useAsync';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  HomePageAlerts,
  HomePageRSSItem,
  HomePageLinks,
  HomePageMyTeams,
  HomePageOwnershipCard,
  HomePageJSONItem,
  HomePageGithubIssues,
  DependabotAlerts,
  LatestGithubWorkflowRuns,
  DefectdojoVulnerabilities,
  ReleaseRSSFeed,
} from '@internal/plugin-cheetah';

export const HomePage = () => {
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const { value, loading, error } = useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const profile = await identityApi.getProfileInfo();
    return { identity, profile };
  });

  if (error) {
    return <ErrorPanel error={error} />;
  }

  if (loading || !value || !value.identity || !value.profile) {
    return <Progress />;
  }

  const githubSecurity = configApi.getOptionalConfig(
    'app.homepage.githubSecurity',
  );
  const githubWorkflows = configApi.getOptionalConfig(
    'app.homepage.githubWorkflows',
  );
  const rssFeedConfig = configApi.getOptionalConfig('app.homepage.rssFeed');
  const jsonFeedConfig = configApi.getOptionalConfig('app.homepage.jsonFeed');
  const issueFeedConfig = configApi.getOptionalConfig('app.homepage.issueFeed');
  const linksConfig = configApi.getOptionalConfigArray('app.homepage.links');
  const additionalLinksConfig = configApi.getOptionalConfigArray(
    'app.homepage.additionalLinks',
  );
  const releaseRssFeedConfig = configApi.getOptionalConfig(
    'app.homepage.releaseRssFeed',
  );
  const userEntityRef = parseEntityRef(value.identity.userEntityRef);

  return (
    <Page themeId="home">
      <Content>
        <Grid container justifyContent="center" spacing={6}>
          <Header
            type="home"
            title={`Welcome ${value.profile.displayName}`}
            subtitle=""
          >
            <HeaderLabel label="Name" value={userEntityRef.name} />
            <HeaderLabel label="Namespace" value={userEntityRef.namespace} />
            <HeaderLabel label="Kind" value={userEntityRef.kind} />
          </Header>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <HomePageMyTeams title="My teams" />
            </Grid>

            <Grid item xs={12} md={4}>
              <HomePageLinks
                title="Dataplatform links"
                links={linksConfig!.map(linkConfig => {
                  return {
                    title: linkConfig.getOptionalString('title'),
                    url: linkConfig.getString('url'),
                    Icon: linkConfig.getOptionalString('icon'),
                  };
                })}
              />
            </Grid>

            {releaseRssFeedConfig && (
              <Grid item xs={12} md={4}>
                <ReleaseRSSFeed
                  title={`${releaseRssFeedConfig.getString(
                    'feedName',
                  )} Releases - Last 30 Days`}
                  itemProperty="description"
                  shouldLink
                  feedURL={releaseRssFeedConfig.getString('feedUrl')}
                />
              </Grid>
            )}

            {additionalLinksConfig?.map((additionalLinkConfig, index) => (
              <Grid item xs={12} md={4} key={index}>
                <HomePageLinks
                  title={additionalLinkConfig.getOptionalString('title')}
                  links={additionalLinkConfig
                    .getOptionalConfigArray('links')!
                    .map(link => {
                      return {
                        title: link.getOptionalString('title'),
                        url: link.getString('url'),
                        Icon: link.getOptionalString('icon'),
                      };
                    })}
                />
              </Grid>
            ))}

            <Grid item xs={8} md={4}>
              <HomePageOwnershipCard title="My Ownership" />
            </Grid>

            {configApi.getOptionalBoolean(
              'app.homepage.alerts.showAlertsFeed',
            ) && (
              <Grid item xs={12} md={4}>
                <HomePageAlerts title="Alerts" />
              </Grid>
            )}

            {jsonFeedConfig && (
              <>
                {jsonFeedConfig.getOptionalBoolean('showJsonFeed') && (
                  <Grid item xs={12} md={4}>
                    <HomePageJSONItem
                      title={jsonFeedConfig.getString('feedName')}
                      linkSelector="url"
                      titleSelector="title"
                      descriptionSelector="body"
                      shouldLink
                      arraySelector="items"
                      jsonUrl={jsonFeedConfig.getString('feedUrl')}
                    />
                  </Grid>
                )}
              </>
            )}

            {rssFeedConfig && (
              <>
                {rssFeedConfig.getOptionalBoolean('showLatestFeed') && (
                  <Grid item xs={12} md={4}>
                    <HomePageRSSItem
                      title={`Todays ${rssFeedConfig.getString('feedName')}`}
                      itemProperty="description"
                      shouldLink
                      feedURL={rssFeedConfig.getString('feedUrl')}
                    />
                  </Grid>
                )}
              </>
            )}

            {issueFeedConfig && (
              <>
                {issueFeedConfig.getOptionalBoolean('showGithubIssues') && (
                  <Grid container spacing={3} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                      <HomePageGithubIssues
                        title={issueFeedConfig.getString('feedName')}
                        reposFilterRegex={issueFeedConfig.getString(
                          'reposFilterRegex',
                        )}
                        showIssueTypes="AllExceptBot"
                        layoutStyle="default"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <HomePageGithubIssues
                        title={`${issueFeedConfig.getString('feedName')} (bot)`}
                        reposFilterRegex={issueFeedConfig.getString(
                          'reposFilterRegex',
                        )}
                        showIssueTypes="Bot"
                        layoutStyle="compact"
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {configApi.getOptionalBoolean(
              'app.homepage.defectDojo.showVulnerabilities',
            ) && (
              <>
                <Grid item xs={12} md={12}>
                  <DefectdojoVulnerabilities
                    severity={configApi.getOptionalString(
                      'app.homepage.defectDojo.severity',
                    )}
                    title="Vulnerabilities"
                  />
                </Grid>
              </>
            )}

            {githubSecurity && (
              <>
                {githubSecurity.getOptionalBoolean('showSecurityAlerts') && (
                  <>
                    <Grid item xs={12} md={12}>
                      <DependabotAlerts
                        reposFilterRegex={githubSecurity.getString(
                          'reposFilterRegex',
                        )}
                        title={githubSecurity.getString('title')}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            {/* todo: config.d.ts and set filter to default failures and add failure to dynamic title */}
            {githubWorkflows && (
              <>
                {githubWorkflows.getOptionalBoolean('showWorkflows') && (
                  <>
                    <Grid item xs={12} md={12}>
                      <LatestGithubWorkflowRuns
                        reposFilterRegex={githubWorkflows.getString(
                          'reposFilterRegex',
                        )}
                        title={githubWorkflows.getString('title')}
                        filterSuccessfulWorkflows={githubWorkflows.getOptionalBoolean(
                          'filterSuccessfulWorkflows',
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
