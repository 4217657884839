import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useProducerTopicsForEntity } from './useProducerTopicsForEntity';
import { kafkaApiRef } from '../../api/types';

export const useProducerTopicsOffsetsForEntity = () => {
  const producers = useProducerTopicsForEntity();
  const api = useApi(kafkaApiRef);
  const errorApi = useApi(errorApiRef);
  const consumerGroup = 'producer';

  const {
    loading,
    value: producerGroupsTopics,
    retry,
  } = useAsyncRetry(async () => {
    try {
      if (!producers) return Promise.resolve([]);
      return await Promise.all(
        producers.map(async ({ clusterId, topic }) => {
          const response = await api.getTopicsOffsets(clusterId, topic);
          return { clusterId, consumerGroup, topics: response.offsets };
        }),
      );
    } catch (err) {
      if (err instanceof Error) {
        errorApi.post(err);
      }
      throw err;
    }
  }, [producers, api, errorApi]);

  return [
    {
      loading,
      producerGroupsTopics,
    },
    {
      retry,
    },
  ] as const;
};
