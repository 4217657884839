/**
 * @public
 */

export const KUBERNETES_INSTANCE_ANNOTATION = 'backstage.io/kubernetes-id';
export const KUBERNETES_NAMESPACE_ANNOTATION =
  'backstage.io/kubernetes-namespace';
export const OPENCOST_AGGREGATE_NAMESPACE_ANNOTATION =
  'backstage.io/opencost-aggregate-namespace';
export const KAFKA_INPUT_TOPIC_SELECTOR_ANNOTATION =
  'backstage.io/kafka-input-topics-selector';
export const KAFKA_OUTPUT_TOPIC_SELECTOR_ANNOTATION =
  'backstage.io/kafka-output-topics-selector';
export const KUBERNETES_LABEL_SELECTOR =
  'backstage.io/kubernetes-label-selector';
export const KAFKA_CONSUMER_GROUP_ANNOTATION =
  'kafka.apache.org/consumer-groups';
export const KAFKA_PRODUCER_OUTPUTTOPICS_ANNOTATION =
  'kafka.apache.org/output-topics';
export const KAFKA_PRODUCER_INPUTTOPICS_ANNOTATION =
  'kafka.apache.org/input-topics';
export const VAULT_SECRET_PATH_ANNOTATION = 'vault.io/secrets-path';
export const KAFKA_DASHBOARD_URL = 'kafka.apache.org/dashboard-urls';

export const BACKSTAGE_TECHDOCS_REF_ANNOTATION = 'backstage.io/techdocs-ref';
