import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
  UserSettingsProfileCard,
  UserSettingsAppearanceCard,
  UserSettingsIdentityCard,
} from '@backstage/plugin-user-settings';
import { CheetahUserSettingsBackstageVersionCard } from './CheetahUserSettingsBackstageVersionCard';

/** @public */
export const CheetahUserSettingsGeneral = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <UserSettingsProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsAppearanceCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsIdentityCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <CheetahUserSettingsBackstageVersionCard />
      </Grid>
    </Grid>
  );
};
