import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { EntityFilterQuery } from '@backstage/catalog-client';

export const useBackstageCatalogData = () => {
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const filter: EntityFilterQuery = [{ kind: ['Component'] }];
  const {
    loading,
    value: content,
    retry,
  } = useAsyncRetry(async () => {
    return catalogApi
      .getEntities({ filter: filter })
      .then(result =>
        result.items.map(entities => ({
          componentName: entities?.metadata?.name,
          id:
            entities?.metadata?.annotations?.['backstage.io/kubernetes-id'] ??
            '',
          nameSpace:
            entities?.metadata?.annotations?.[
              'backstage.io/kubernetes-namespace'
            ] ?? '',
          system: entities?.spec?.system,
          owner: entities?.spec?.owner,
        })),
      )
      .catch(err => {
        errorApi.post(err);
        throw err;
      });
  });

  return [{ loading, content }, { retry }] as const;
};
