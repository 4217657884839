import { DependencyGraphTypes } from '@backstage/core-components';
import { EntityNode } from '@backstage/plugin-catalog-graph';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import {
  CHEETAH_CUSTOM_TYPES_FLINK,
  CHEETAH_CUSTOM_TYPES_TOPIC,
} from '@internal/plugin-cheetah-common';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { EntityKindIcon } from './internal/EntityKindIcon';

const useStyles = makeStyles(
  theme => ({
    node: {
      fill: theme.palette.grey[300],
      stroke: theme.palette.grey[300],

      '&.primary': {
        fill: theme.palette.primary.light,
        stroke: theme.palette.primary.light,
      },
      '&.secondary': {
        fill: theme.palette.secondary.light,
        stroke: theme.palette.secondary.light,
      },
      '&.flinkjob': {
        fill: theme.palette.secondary.dark,
        stroke: theme.palette.secondary.dark,
      },
      '&.api': {
        fill: theme.palette.success.light,
        stroke: theme.palette.success.light,
      },
      '&.topic': {
        fill: theme.palette.warning.light,
        stroke: theme.palette.warning.light,
      },
      '&.system': {
        fill: theme.palette.error.light,
        stroke: theme.palette.error.light,
      },
    },
    text: {
      fill: theme.palette.getContrastText(theme.palette.grey[300]),

      '&.primary': {
        fill: theme.palette.primary.contrastText,
      },
      '&.secondary': {
        fill: theme.palette.secondary.contrastText,
      },
      '&.focused': {
        fontWeight: 'bold',
      },
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
  { name: 'PluginCatalogGraphCustomNode' },
);

export function CustomNode(
  {
    node: { id, entity, color = 'default', focused, onClick },
  }: DependencyGraphTypes.RenderNodeProps<EntityNode>,
  isKafkaTopic: boolean,
  isFlinkJob: boolean,
): React.JSX.Element {
  let kind = entity.kind;
  const name = entity.metadata.name;
  const namespace = entity.metadata.namespace;
  const title = entity.metadata.title;

  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const idRef = useRef<SVGTextElement | null>(null);

  useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const padding = 10;
  const iconSize = height;
  const paddedIconWidth = kind ? iconSize + padding : 0;
  const paddedWidth = paddedIconWidth + width + padding * 2;
  const paddedHeight = height + padding * 2;

  if (isFlinkJob) {
    // eslint-disable-next-line no-param-reassign
    kind = CHEETAH_CUSTOM_TYPES_FLINK;
  }

  if (isKafkaTopic) {
    // eslint-disable-next-line no-param-reassign
    kind = CHEETAH_CUSTOM_TYPES_TOPIC;
  }

  const displayTitle =
    title ??
    (kind && name && namespace
      ? humanizeEntityRef({ kind, name, namespace })
      : id);

  return (
    <g onClick={onClick} className={classNames(onClick && classes.clickable)}>
      <rect
        className={classNames(
          classes.node,
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
          isFlinkJob && 'flinkjob',
          kind?.toLowerCase() === 'api' && 'api',
          isKafkaTopic && 'topic',
          kind?.toLowerCase() === 'system' && 'system',
        )}
        width={paddedWidth}
        height={paddedHeight}
        rx={10}
      />
      {kind && (
        <EntityKindIcon
          kind={kind}
          y={padding}
          x={padding}
          width={iconSize}
          height={iconSize}
          className={classNames(
            classes.text,
            focused && 'focused',
            color === 'primary' && 'primary',
            color === 'secondary' && 'secondary',
          )}
        />
      )}
      <text
        ref={idRef}
        className={classNames(
          classes.text,
          focused && 'focused',
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
        )}
        y={paddedHeight / 2}
        x={paddedIconWidth + (width + padding * 2) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {displayTitle}
      </text>
    </g>
  );
}
