import { DependencyGraphTypes } from '@backstage/core-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import classNames from 'classnames';
import { EntityEdgeData } from '@backstage/plugin-catalog-graph';
import {
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';

const useStyles = makeStyles(
  theme => ({
    text: {
      fill: theme.palette.textContrast,
    },
    secondary: {
      fill: theme.palette.textSubtle,
    },
  }),
  { name: 'PluginCatalogGraphCustomLabel' },
);
export function CustomLabel({
  edge: { relations },
}: DependencyGraphTypes.RenderLabelProps<EntityEdgeData>) {
  const classes = useStyles();
  return (
    <text className={classes.text} textAnchor="middle">
      {relations.map((r, i) => {
        const displayText = () => {
          // set custom text
          switch (r) {
            case RELATION_DEPENDS_ON:
              return 'Uses';
            case RELATION_HAS_PART:
              return '';
            default:
              return r;
          }
        };
        return (
          <tspan
            key={displayText()}
            className={classNames(i > 0 && classes.secondary)}
          >
            {i > 0 && <tspan> / </tspan>}
            {displayText()}
          </tspan>
        );
      })}
    </text>
  );
}
