import React, { useEffect, useState } from 'react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { Grid } from '@material-ui/core';
import {
  LayoutTemplate,
  createScaffolderLayout,
} from '@backstage/plugin-scaffolder-react';

const ShowDiagram: LayoutTemplate = ({ description, uiSchema }) => {
  const [icon, setIcon] = useState('');

  const diagramType = uiSchema?.['ui:options']?.diagramType ?? '';

  useEffect(() => {
    const fetchData = async () => {
      let importedIcon: typeof import('*.svg');

      switch (diagramType) {
        case 'component-diagram':
          importedIcon = (await import(
            '../assets/generated/deploy-component-team.svg'
          )) as typeof import('*.svg');
          break;
        case 'system-diagram':
          importedIcon = (await import(
            '../assets/generated/deploy-system-team.svg'
          )) as typeof import('*.svg');
          break;
        case 'teamrepo-diagram':
          importedIcon = (await import(
            '../assets/generated/bootstrap-flux-team.svg'
          )) as typeof import('*.svg');
          break;
        case 'webapi-diagram':
          importedIcon = (await import(
            '../assets/generated/bootstrap-webapi.svg'
          )) as typeof import('*.svg');
          break;
        case 'flinkrepo-diagram':
          importedIcon = (await import(
            '../assets/generated/bootstrap-flinkrepo.svg'
          )) as typeof import('*.svg');
          break;
        default:
          setIcon('');
          return;
      }
      setIcon(importedIcon.default);
    };
    fetchData();
  }, [setIcon, diagramType]);

  if (!icon) {
    return <>{description}</>;
  }

  return (
    <>
      <Grid container justifyContent="flex-start">
        <Grid item xs={6} key="diagram">
          <img src={icon} alt="overview of action" />
          <br />
          {description}
        </Grid>
      </Grid>
    </>
  );
};

export const ShowDiagramLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'ShowDiagram',
    component: ShowDiagram,
  }),
);
