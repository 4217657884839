import { Box, Typography } from '@material-ui/core';
import RetryIcon from '@material-ui/icons/Replay';
import React from 'react';
import { Table, TableColumn, Link } from '@backstage/core-components';
import SearchIcon from '@material-ui/icons/Search';
import { TableTopicInfo } from '../../lib/types';

const generatedColumns: TableColumn[] = [
  {
    title: 'Topic',
    field: 'topic',
    highlight: true,
    render: (row: Partial<TableTopicInfo>) => {
      if (row.hasLink) {
        return (
          <Link to={row.link!} target="_blank" underline="none">
            <SearchIcon fontSize="small" />
            <Typography>
              {row.topic}
              <br />
            </Typography>
          </Link>
        );
      }
      return <>{row.topic ?? ''}</>;
    },
  },
  {
    title: 'Partition',
    field: 'partitionId',
    render: (row: Partial<TableTopicInfo>) => {
      return <>{row.partitionId ?? ''}</>;
    },
  },
  {
    title: 'Topic Offset',
    field: 'topicOffset',
    render: (row: Partial<TableTopicInfo>) => {
      return <>{row.topicOffset ?? ''}</>;
    },
  },
  {
    title: 'Group Offset',
    field: 'groupOffset',
    render: (row: Partial<TableTopicInfo>) => {
      return <>{row.groupOffset ?? ''}</>;
    },
  },
  {
    title: 'Lag',
    field: 'lag',
    render: (row: Partial<TableTopicInfo>) => {
      let lag = undefined;
      if (row.topicOffset && row.groupOffset) {
        lag = +row.topicOffset - +row.groupOffset;
      }
      return <>{lag ?? ''}</>;
    },
  },
];

type Props = {
  loading: boolean;
  retry: () => void;
  clusterId: string;
  consumerGroupUrl?: string;
  consumerGroup: string;
  topics: TableTopicInfo[];
};

export const ConsumerGroupOffsets = ({
  loading,
  topics,
  clusterId,
  consumerGroupUrl,
  consumerGroup,
  retry,
}: Props) => {
  if (!topics.length) {
    return null;
  }
  return (
    <Table
      isLoading={loading}
      actions={[
        {
          icon: () => <RetryIcon />,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => retry(),
        },
      ]}
      data={topics ?? []}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            Consumed topics for{' '}
            {(consumerGroupUrl && (
              <Link to={consumerGroupUrl} target="_blank">
                {consumerGroup}
              </Link>
            )) ||
              consumerGroup}
            &nbsp;({clusterId})
          </Typography>
        </Box>
      }
      columns={generatedColumns}
    />
  );
};
