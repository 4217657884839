import { Entity, RELATION_PART_OF } from '@backstage/catalog-model';
import {
  EntityFilter,
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';

/**
 * Filter matching entities that are owned by system.
 * @public
 */
export class EntitySystemFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    return this.values.some(v =>
      getEntityRelations(entity, RELATION_PART_OF).some(
        o => humanizeEntityRef(o, { defaultKind: 'system' }) === v,
      ),
    );
  }

  toQueryValue(): string[] {
    return this.values;
  }
}

export class EntityCheetahInfrastructureFilter implements EntityFilter {
  constructor(readonly hideInfrastructure: boolean) {}
  filterEntity(entity: Entity): boolean {
    const isInfrastucture = entity.spec?.type === 'infrastructure';
    return this.hideInfrastructure ? !isInfrastucture : isInfrastucture;
  }
}
