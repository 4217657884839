import { createApiRef } from '@backstage/core-plugin-api';
import {
  AvailableRepositoriesResponse,
  AvailableSecurityAlertsResponse,
  AvailableIssuesResponse,
  GitlabRepositoriesResponse,
  AvailablePackageVersionsResponse,
  OpenCostSummaryStep,
  GithubLatestWorkflowsResponse,
  DefectdojoVulnerabilitiesResponse,
} from '@internal/plugin-cheetah-common';

export const cheetahApiRef = createApiRef<CheetahApi>({
  id: 'plugin.cheetah.service',
});

// CheetahApi defines the interface for the backend gateway used by the
// frontend cheetah plugin
export interface CheetahApi {
  // getAvailableRepositories returns a list of trifork repositories, which
  // match a certain regex filter.
  // e.g. one could select all repositories, which have the 'cheetah-' prefix
  getGithubRepositories(
    regexFilter: string,
    headers?: Headers,
  ): Promise<AvailableRepositoriesResponse>;

  getGithubIssues(
    regexFilter: string,
    headers?: Headers,
  ): Promise<AvailableIssuesResponse>;

  getGithubSecurityAlerts(
    regexFilter: string,
    headers?: Headers,
  ): Promise<AvailableSecurityAlertsResponse>;

  getDefectdojoVulnerabilities(
    severity: string,
    headers?: Headers,
  ): Promise<DefectdojoVulnerabilitiesResponse>;

  getGithubLatestWorkflows(
    regexFilter: string,
    headers?: Headers,
  ): Promise<GithubLatestWorkflowsResponse>;

  getGitlabRepositories(
    regexFilter: string,
    headers?: Headers,
  ): Promise<GitlabRepositoriesResponse>;

  getGithubImageTags(props: {
    org: string;
    package_name: string;
    headers?: Headers;
  }): Promise<AvailablePackageVersionsResponse>;

  getKubernetesNameSpaces(): Promise<string[]>;

  getKubernetesPodIds(): Promise<string[]>;

  getComputeAllocation(
    aggregate: string,
    window: string,
    accumulate?: boolean,
    headers?: Headers,
  ): Promise<OpenCostSummaryStep[]>;
}
