// https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/CatalogPage/DefaultCatalogPage.tsx
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogKindHeader } from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
} from '@backstage/plugin-catalog-react';
import React, { useMemo } from 'react';
import { CatalogTable, CatalogTableRow } from '../CatalogTable';
import { columnFactories } from '../CatalogTable/columns';
import { CheetahEntityInfrastructureFilter } from '../CheetahEntityInfrastructureFilter';

/**
 * Props for root catalog pages.
 *
 * @public
 */
export interface StandardAppsPageProps {
  initialKind?: string;
  hideInfrastructure: boolean;
  pageTitle?: string;
  description?: string;
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
}

export function StandardAppsPage(props: StandardAppsPageProps) {
  const {
    pageTitle,
    description,
    hideInfrastructure,
    actions,
    initialKind = 'platform',
    tableOptions = {},
  } = props;

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    const titleColumn = columnFactories.createTitleColumn({ hidden: true });
    const nameColumn = columnFactories.createNameColumn({
      defaultKind: initialKind,
    });
    const descriptionColumn = columnFactories.createMetadataDescriptionColumn();
    descriptionColumn.width = '100%';
    return [
      titleColumn,
      nameColumn,
      descriptionColumn,
      columnFactories.createTagsColumn(),
      columnFactories.createSpecLifecycleColumn(),
    ];
  }, [initialKind]);

  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  return (
    <PageWithHeader title={pageTitle || `${orgName} Catalog`} themeId="home">
      <EntityListProvider>
        <Content>
          <ContentHeader
            //  title='Standard Apps'
            description={description}
            titleComponent={
              <CatalogKindHeader
                initialFilter={initialKind}
                allowedKinds={[initialKind]}
              />
            }
          >
            <SupportButton>Support pages</SupportButton>
          </ContentHeader>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityLifecyclePicker />
              <CheetahEntityInfrastructureFilter
                hideInfrastructure={hideInfrastructure}
              />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable
                columns={defaultColumns}
                actions={actions}
                tableOptions={tableOptions}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
}
