import React, { useState } from 'react';
import { FieldValidation } from '@rjsf/utils';
import { useApi } from '@backstage/core-plugin-api';
import { ErrorPanel, Progress, SelectItem } from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import lodash from 'lodash';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import { cheetahApiRef } from '../../api/types'; // todo
import { cheetahImageTagPickerConfigSchema } from './types';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const CheetahImageTagPicker = (
  props: FieldExtensionComponentProps<string, {}>,
): React.ReactElement => {
  const [dropDownData, setDropDownData] = useState<SelectItem[] | undefined>();
  const [selected, setSelected] = useState<string | undefined>();

  const {
    schema: {
      title = 'Image tag',
      description = 'An tag for the container image',
    },
    required,
    rawErrors,
    uiSchema,
    formData,
    idSchema,
    formContext,
  } = props;

  const cheetahBackend = useApi(cheetahApiRef);
  const integrationApi = useApi(scmIntegrationsApiRef);

  const options = cheetahImageTagPickerConfigSchema.parse(
    uiSchema!['ui:options'],
  );

  const hostType = integrationApi.byHost(options.host)?.type;

  const formRecords: Record<string, any> = formContext.formData;
  const fieldValue = lodash.find(formRecords, (_, fieldName) => {
    return fieldName === (options.fieldName || 'packageRepository');
  });

  const { error, loading } = useAsync(async () => {
    let versions = Array<{ tag: string; created_date: string }>();
    if (fieldValue) {
      const packageName = fieldValue.split('/').pop();
      if (hostType === 'github') {
        versions = await cheetahBackend
          .getGithubImageTags({ org: options.org, package_name: packageName })
          .then(result => result.tags);
      }
    }
    const localDropDownData = lodash
      .chain(versions)
      .map(item => {
        const value = item.tag;
        const label = `${item.tag} (${item.created_date})`;

        return {
          value,
          label: label || value,
        };
      })
      // TODO: filter?
      .value();

    setDropDownData(localDropDownData);
    // setSelected(xx)
  }, [fieldValue]);

  if (error) {
    return <ErrorPanel error={error} />;
  }

  if (!dropDownData) {
    return <Progress />;
  }

  if (selected) {
    props.onChange(selected);
  }

  const availableTags: string[] =
    dropDownData.map(s => s.value as string) || [];
  let currentValue = '';
  if (availableTags.length) {
    currentValue = (formData as string) || ('' as string);
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={Boolean(rawErrors?.length && !formData)}
    >
      <Autocomplete
        disabled={!fieldValue}
        id={idSchema?.$id}
        options={availableTags}
        value={currentValue}
        loading={loading}
        onChange={(_: object, value: string | null) => {
          if (value === null) {
            setSelected(undefined);
          } else {
            setSelected(String(value));
          }
          props.onChange(String(value));
        }}
        autoSelect
        freeSolo={options.allowArbitraryValues || true}
        renderOption={k =>
          dropDownData.find(s => s.value === k)?.label ?? (k as string)
        }
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};

export const CheetahImageTagPickerValidation = (
  // TODO
  value: string,
  validation: FieldValidation,
) => {
  if (
    value &&
    value.length < 2 // || !KubernetesValidatorFunctions.isValidObjectName(value)
  ) {
    validation.addError(
      'must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
};
