import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      viewBox="0 0 1079.95 106.05"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>logo-backstage-white</title>
      <defs>
        <polygon
          id="path-1"
          points="0.06 0.133 62.132 0.133 62.132 50.443 0.06 50.443"
        />
      </defs>
      <g
        id="logo-backstage-white"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-3" transform="translate(0.000000, 1.000000)">
          <g id="Clip-2" />
          <polyline
            id="Fill-1"
            fill="#FFFFFE"
            mask="url(#mask-2)"
            points="0 0.133 62.132 0.133 62.132 12.643 39.22 12.643 39.22 50.443 22.914 50.443 22.914 12.643 0 12.643 0 0.133"
          />
        </g>
        <path
          d="M85.567,25.873 L112.521,25.873 C116.927,25.873 118.544,24.205 118.544,20.59 L118.544,18.926 C118.544,14.475 116.192,13.643 111.052,13.643 L85.567,13.643 L85.567,25.873 Z M69.263,1.133 L118.47,1.133 C131.028,1.133 134.407,7.039 134.407,16.074 L134.407,19.27 C134.407,26.014 132.792,29.971 125.298,31.641 L125.298,31.779 C130.146,32.613 134.187,34.631 134.187,42.898 L134.187,51.443 L117.883,51.443 L117.883,45.4 C117.883,40.119 116.267,38.379 111.197,38.379 L85.567,38.379 L85.567,51.443 L69.263,51.443 L69.263,1.133 L69.263,1.133 Z"
          id="Fill-4"
          fill="#FFFFFE"
        />
        <polygon
          id="Fill-5"
          fill="#FFFFFE"
          points="146.312 51.444 162.614 51.444 162.614 1.133 146.312 1.133"
        />
        <polyline
          id="Fill-6"
          fill="#FFFFFE"
          points="174.513 1.133 230.036 1.133 230.036 13.225 190.819 13.225 190.819 22.609 228.201 22.609 228.201 34.281 190.819 34.281 190.819 51.443 174.513 51.443 174.513 1.133"
        />
        <path
          d="M255.453,28.998 C255.453,36.295 257.069,39.076 264.78,39.076 L285.637,39.076 C293.352,39.076 294.964,36.295 294.964,28.998 L294.964,23.58 C294.964,17.326 293.352,13.504 284.76,13.504 L265.662,13.504 C257.069,13.504 255.453,17.326 255.453,23.58 L255.453,28.998 Z M238.711,18.785 C238.711,4.053 247.449,0.58 262.139,0.58 L288.282,0.58 C302.971,0.58 311.711,4.053 311.711,18.785 L311.711,33.793 C311.711,48.527 302.971,52 288.282,52 L262.139,52 C247.449,52 238.711,48.527 238.711,33.793 L238.711,18.785 L238.711,18.785 Z"
          id="Fill-7"
          fill="#FFFFFE"
        />
        <path
          d="M337.866,25.873 L364.817,25.873 C369.227,25.873 370.838,24.205 370.838,20.59 L370.838,18.926 C370.838,14.475 368.488,13.643 363.35,13.643 L337.866,13.643 L337.866,25.873 Z M321.559,1.133 L370.765,1.133 C383.328,1.133 386.703,7.039 386.703,16.074 L386.703,19.27 C386.703,26.014 385.088,29.971 377.598,31.641 L377.598,31.779 C382.445,32.613 386.486,34.631 386.486,42.898 L386.486,51.443 L370.178,51.443 L370.178,45.4 C370.178,40.119 368.563,38.379 363.496,38.379 L337.866,38.379 L337.866,51.443 L321.559,51.443 L321.559,1.133 L321.559,1.133 Z"
          id="Fill-8"
          fill="#FFFFFE"
        />
        <polyline
          id="Fill-9"
          fill="#FFFFFE"
          points="397.728 1.133 414.027 1.133 414.027 19.619 421.67 19.619 442.23 1.133 464.412 1.133 435.623 25.734 467.791 51.443 444.728 51.443 421.227 32.129 414.027 32.129 414.027 51.443 397.728 51.443 397.728 1.133"
        />
        <polygon
          id="Fill-10"
          fill="#FF6600"
          points="478.816 51.444 494.238 51.444 494.238 37.268 478.816 37.268"
        />
        <path
          d="M475.373,3.252 L473.843,3.252 L473.843,4.985 L475.395,4.985 C475.855,4.985 476.315,4.718 476.315,4.119 C476.315,3.359 475.727,3.252 475.373,3.252 Z M476.69,7.158 L476.229,7.158 L475.245,5.371 L473.843,5.371 L473.843,7.158 L473.447,7.158 L473.447,2.867 L475.373,2.867 C476.208,2.867 476.711,3.337 476.711,4.119 C476.711,4.771 476.294,5.242 475.662,5.349 L476.69,7.158 Z M474.881,1.507 C472.965,1.507 471.414,3.07 471.414,5.007 C471.414,6.933 472.965,8.496 474.881,8.496 C476.797,8.496 478.359,6.933 478.359,5.007 C478.359,3.07 476.797,1.507 474.881,1.507 Z M474.881,8.881 C472.751,8.881 471.018,7.137 471.018,5.007 C471.018,2.867 472.751,1.133 474.881,1.133 C477.011,1.133 478.755,2.867 478.755,5.007 C478.755,7.137 477.011,8.881 474.881,8.881 L474.881,8.881 Z"
          id="Fill-11"
          fill="#FFFFFE"
        />
      </g>
    </svg>
  );
};

export default LogoFull;
