import {
  Cost,
  DateAggregation,
  Entity,
  Group,
  MetricData,
  Project,
} from '@backstage-community/plugin-cost-insights-common';
import _ from 'lodash';
import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
  DEFAULT_DATE_FORMAT,
  ProjectGrowthAlert,
  ProjectGrowthData,
} from '@backstage-community/plugin-cost-insights';
import {
  aggregationFor,
  changeOf,
  changeOfNumbers,
  entityOf,
  parseIntervals,
  parseIntervalsExtended,
  trendlineOf,
} from './utils';
import { openCostSummaryMatchesEntity } from './opencostsapi';
import { DateTime } from 'luxon';
import { CheetahApi } from '../api/types';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { parseEntityRef, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import {
  KUBERNETES_NAMESPACE_ANNOTATION,
  OpenCostSummaryStep,
  OPENCOST_AGGREGATE_NAMESPACE_ANNOTATION,
} from '@internal/plugin-cheetah-common';
import { inclusiveEndDateOf, inclusiveStartDateOf } from './duration';

export class CostInsightsClient implements CostInsightsApi {
  cheetahBackendApi: CheetahApi;
  catalogApi: CatalogApi;
  identityApi: IdentityApi;

  /**
   *
   */
  constructor(
    cheetahBackendApi: CheetahApi,
    catalogApi: CatalogApi,
    identityApi: IdentityApi,
  ) {
    this.cheetahBackendApi = cheetahBackendApi;
    this.catalogApi = catalogApi;
    this.identityApi = identityApi;
  }

  /**
   * Get the most current date for which billing data is complete, in YYYY-MM-DD format. This helps
   * define the intervals used in other API methods to avoid showing incomplete cost. The costs for
   * today, for example, will not be complete. This ideally comes from the cloud provider.
   */
  async getLastCompleteBillingDate(): Promise<string> {
    return Promise.resolve(
      DateTime.now().minus({ days: 1 }).toFormat(DEFAULT_DATE_FORMAT),
    );
  }

  /**
   * Get a list of groups the given user belongs to. These may be LDAP groups or similar
   * organizational groups. Cost Insights is designed to show costs based on group membership;
   * if a user has multiple groups, they are able to switch between groups to see costs for each.
   *
   * This method should be removed once the Backstage identity plugin provides the same concept.
   *
   * @param userId - The login id for the current user
   */
  async getUserGroups(_userId: string): Promise<Group[]> {
    const owner = await this.identityApi.getBackstageIdentity();
    /*     const ownerGroups = getEntityRelations(owner, RELATION_MEMBER_OF, {
      kind: 'Group',
    }); */

    const ownedEntitiesList = await this.catalogApi.getEntities({
      filter: [
        {
          kind: ['Component'],
          'relations.ownedBy': owner.ownershipEntityRefs,
        },
      ],
      fields: ['metadata.name', 'relations'],
    });

    const groups: Group[] = owner.ownershipEntityRefs
      .filter(ownershipEntityRef => {
        // Only show groups with entities?
        return ownedEntitiesList.items.some(c =>
          c.relations?.some(
            r =>
              r.type === RELATION_OWNED_BY &&
              r.targetRef === ownershipEntityRef,
          ),
        );
      })
      .map(ownershipEntityRef => {
        return {
          id: ownershipEntityRef,
          name: parseEntityRef(ownershipEntityRef).name,
        } as Group;
      });

    groups.push({
      id: 'all',
      name: 'All',
    });

    return groups;
  }

  /**
   * Get a list of cloud billing entities that belong to this group (projects in GCP, AWS has a
   * similar concept in billing accounts). These act as filters for the displayed costs, users can
   * choose whether they see all costs for a group, or those from a particular owned project.
   *
   * @param group - The group id from getUserGroups or query parameters
   */
  async getGroupProjects(_group: string): Promise<Project[]> {
    // namespace?
    const projects: Project[] = [{ id: 'cheetah-dev' }];

    return projects;
  }

  /**
   * Get aggregations for a particular metric and interval time frame. Teams
   * can see metrics important to their business in comparison to the growth
   * (or reduction) of a project or group's daily costs.
   *
   * @param metric - A metric from the cost-insights configuration in app-config.yaml.
   * @param intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */
  async getDailyMetricData(
    metric: string,
    intervals: string,
  ): Promise<MetricData> {
    const { duration, endDate } = parseIntervals(intervals);
    const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
    const days = DateTime.fromISO(endDate).diff(
      DateTime.fromISO(inclusiveStartDateOf(duration, inclusiveEndDate)),
      'days',
    ).days;

    const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
      'namespace',
      `${days}d`,
    );

    const aggregation = [...Array(days).keys()].reduce(
      (values: DateAggregation[], i: number): DateAggregation[] => {
        const date = DateTime.fromISO(
          inclusiveStartDateOf(duration, inclusiveEndDate),
        )
          .plus({ days: i })
          .toFormat(DEFAULT_DATE_FORMAT);

        const podSummaryForDate = openCostSummary.find(
          os =>
            DateTime.fromISO(os.date).toFormat(DEFAULT_DATE_FORMAT) === date,
        );

        const namespaces = podSummaryForDate?.summaries.map(s => s.name) || [];

        const teams = _.chain(namespaces)
          .filter(ns =>
            ['-app', '-query', '-process', '-storage'].some(domainWord =>
              ns.includes(domainWord),
            ),
          ) // TODO: move domains to config
          .groupBy(ns => {
            return ns.slice(0, ns.lastIndexOf('-') + 1);
          })
          .value();

        const teamCount = Object.keys(teams).length;
        values.push({
          date: date,
          amount: teamCount,
        });
        return values;
      },
      [],
    );

    return {
      id: metric,
      format: 'number',
      aggregation: aggregation,
      change: changeOf(aggregation),
    };
  }

  /**
   * Get daily cost aggregations for a given group and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about their cost growth (or
   * reduction) and compare it to metrics important to the business.
   *
   * @param group - The group id from getUserGroups or query parameters
   * @param intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */
  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
    const { days } = parseIntervalsExtended(intervals);

    let filteredSummaries: OpenCostSummaryStep[];
    if (group !== 'all') {
      const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
        'pod',
        `${days}d`,
      );

      filteredSummaries = openCostSummary;
      // filter
      const ownedEntitiesList = await this.catalogApi.getEntities({
        filter: [
          {
            kind: ['Component'],
            'relations.ownedBy': [group],
          },
        ],
        fields: ['metadata.annotations'],
      });

      const groupEntities = ownedEntitiesList.items;

      filteredSummaries = openCostSummary.map(e => {
        return {
          date: e.date,
          summaries: _.intersectionWith(
            e.summaries,
            groupEntities,
            (s, entity) => {
              if (entity.metadata) {
                // bugged
                return openCostSummaryMatchesEntity(s, entity);
              }
              return false;
            },
          ),
        } as OpenCostSummaryStep;
      });
    } else {
      const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
        'namespace',
        `${days}d`,
      );

      filteredSummaries = openCostSummary;
    }

    const networkOpenCostSummary =
      group === 'all'
        ? await this.cheetahBackendApi.getComputeAllocation(
            'cluster',
            `${days}d`,
          )
        : filteredSummaries;

    const groupDailyCost: Cost = {
      id: 'cheetah-dev',
      aggregation: aggregationFor(filteredSummaries, intervals, false),
      change: changeOf(aggregationFor(filteredSummaries, intervals, true)),
      trendline: trendlineOf(
        aggregationFor(filteredSummaries, intervals, true),
      ),
      groupedCosts: {
        product: [
          {
            id: 'Compute Engine',
            aggregation: aggregationFor(
              filteredSummaries,
              intervals,
              false,
              'computeEngine',
            ),
          },
          {
            id: 'Cloud Storage',
            aggregation: aggregationFor(
              filteredSummaries,
              intervals,
              false,
              'cloudStorage',
            ),
          },
          {
            id: 'Other',
            aggregation: aggregationFor(
              networkOpenCostSummary,
              intervals,
              false,
              'other',
            ),
          },
        ],
      },
    };

    return groupDailyCost;
  }

  /**
   * Get daily cost aggregations for a given billing entity (project in GCP, AWS has a similar
   * concept in billing accounts) and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about the project's cost growth
   * (or reduction) and compare it to metrics important to the business.
   *
   * @param project - The project id from getGroupProjects or query parameters
   * @param intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */
  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    const { days } = parseIntervalsExtended(intervals);
    const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
      'namespace',
      `${days}d`,
    );

    const networkOpenCostSummary =
      await this.cheetahBackendApi.getComputeAllocation('cluster', `${days}d`);

    const projectDailyCost: Cost = {
      id: project,
      aggregation: aggregationFor(openCostSummary, intervals, false),
      change: changeOf(aggregationFor(openCostSummary, intervals, false)),
      trendline: trendlineOf(aggregationFor(openCostSummary, intervals, false)),
      groupedCosts: {
        product: [
          {
            id: 'Compute Engine',
            aggregation: aggregationFor(
              openCostSummary,
              intervals,
              false,
              'computeEngine',
            ),
          },
          {
            id: 'Cloud Storage',
            aggregation: aggregationFor(
              openCostSummary,
              intervals,
              false,
              'cloudStorage',
            ),
          },
          {
            id: 'Other',
            aggregation: aggregationFor(
              networkOpenCostSummary,
              intervals,
              false,
              'other',
            ),
          },
        ],
      },
    };

    return projectDailyCost;
  }

  /**
   * Get daily cost aggregations for a given catalog entity and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about their cost growth (or
   * reduction) and compare it to metrics important to the business.
   *
   * Note: implementing this is only required when using the `EntityCostInsightsContent` extension.
   *
   * @param catalogEntityRef - A reference to the catalog entity, as described in
   *   https://backstage.io/docs/features/software-catalog/references
   * @param intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */
  async getCatalogEntityDailyCost(
    entityRef: string,
    intervals: string,
  ): Promise<Cost> {
    const entity = await this.catalogApi.getEntityByRef(entityRef);
    const { days } = parseIntervalsExtended(intervals);

    const kubernetesNameSpace =
      entity!.metadata.annotations?.[KUBERNETES_NAMESPACE_ANNOTATION];

    let openCostSummary: OpenCostSummaryStep[];
    let shouldAggregateNamespace = false; // assume we can only look at pod cost

    if (
      entity!.metadata.annotations?.[
        OPENCOST_AGGREGATE_NAMESPACE_ANNOTATION
      ] !== 'true'
    ) {
      openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
        'pod',
        `${days}d`,
      );
      // lets check if there is multiple pods in the namespace
      shouldAggregateNamespace = openCostSummary.some(element => {
        const dict = _.chain(element.summaries)
          .filter(s => s.properties.namespace === kubernetesNameSpace)
          .groupBy(s => s.properties.container)
          .value();
        return (
          dict.items?.length > 1 // replicas share the same container name
        );
      });
    } else {
      shouldAggregateNamespace = true;
    }
    if (!shouldAggregateNamespace) {
      // we are sharing resources and can only look at pod aggregate
      openCostSummary = openCostSummary!.map(a => {
        return {
          date: a.date,
          summaries: a.summaries.filter(s =>
            openCostSummaryMatchesEntity(s, entity!),
          ),
        } as OpenCostSummaryStep;
      });
    } else {
      // we are the only resource in the namespace and can set aggregate to namespace
      openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
        'namespace',
        `${days}d`,
      );

      openCostSummary = openCostSummary.map(a => {
        return {
          date: a.date,
          summaries: a.summaries.filter(s => s.name === kubernetesNameSpace),
        } as OpenCostSummaryStep;
      });
    }

    const groupDailyCost: Cost = {
      id: entityRef,
      aggregation: aggregationFor(openCostSummary, intervals, false),
      change: changeOf(aggregationFor(openCostSummary, intervals, false)),
      trendline: trendlineOf(aggregationFor(openCostSummary, intervals, false)),
      groupedCosts: {
        product: [
          {
            id: 'Compute Engine',
            aggregation: aggregationFor(
              openCostSummary,
              intervals,
              false,
              'computeEngine',
            ),
          },
          {
            id: 'Cloud Storage',
            aggregation: aggregationFor(
              openCostSummary,
              intervals,
              false,
              'cloudStorage',
            ),
          },
          {
            id: 'Other',
            aggregation: aggregationFor(
              openCostSummary,
              intervals,
              false,
              'other',
            ),
          },
        ],
      },
    };

    return groupDailyCost;
  }

  /**
   * Get cost aggregations for a particular cloud product and interval time frame. This includes
   * total cost for the product, as well as a breakdown of particular entities that incurred cost
   * in this product. The type of entity depends on the product - it may be deployed services,
   * storage buckets, managed database instances, etc.
   *
   * If project is supplied, this should only return product costs for the given billing entity
   * (project in GCP).
   *
   * The time period is supplied as a Duration rather than intervals, since this is always expected
   * to return data for two bucketed time period (e.g. month vs month, or quarter vs quarter).
   *
   * @param options - Options to use when fetching insights for a particular cloud product and
   *                interval time frame.
   */
  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    const { days, inclusiveEndDate } = parseIntervalsExtended(
      options.intervals,
    );
    const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
      'namespace',
      `${days}d`,
    );

    const splitDate = DateTime.fromISO(inclusiveEndDate).minus({
      days: Math.ceil(days / 2),
    });

    const buckets = [];
    buckets.push(
      openCostSummary.filter(s => DateTime.fromISO(s.date) < splitDate),
    );
    buckets.push(
      openCostSummary.filter(s => DateTime.fromISO(s.date) >= splitDate),
    );

    const productInsights: Entity = entityOf(options.product, buckets);
    return productInsights;
  }

  /**
   * Get current cost alerts for a given group. These show up as Action Items for the group on the
   * Cost Insights page. Alerts may include cost-saving recommendations, such as infrastructure
   * migrations, or cost-related warnings, such as an unexpected billing anomaly.
   */
  async getAlerts(_group: string): Promise<Alert[]> {
    // TODO: storage bytes check

    const intervals = `R1/P90D/${await this.getLastCompleteBillingDate()}`;
    const { duration, endDate } = parseIntervals(intervals);
    const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
    const startDate = inclusiveStartDateOf(duration, inclusiveEndDate);
    const days = DateTime.fromISO(endDate).diff(
      DateTime.fromISO(startDate),
      'days',
    ).days;

    const openCostSummary = await this.cheetahBackendApi.getComputeAllocation(
      'cluster',
      `${days}d`,
    );

    const splitDate = DateTime.fromISO(inclusiveEndDate).minus({
      days: Math.ceil(days / 2),
    });

    const first = openCostSummary.filter(
      s => DateTime.fromISO(s.date) < splitDate,
    );
    const last = openCostSummary.filter(
      s => DateTime.fromISO(s.date) >= splitDate,
    );

    const names = _.chain(openCostSummary)
      .flatMap(s => s.summaries.map(ss => ss.name))
      .uniq()
      .value();

    const projectGrowthDatas: ProjectGrowthData[] = names.map(name => {
      const firstValuesCollectionChain = _.chain(
        first?.flatMap(s => s.summaries),
      ).filter(s => s.name === name);

      const lastValuesCollectionChain = _.chain(
        last?.flatMap(s => s.summaries),
      ).filter(s => s.name === name);

      const firstValue =
        firstValuesCollectionChain.sumBy(s => s.totalCost).value() || 0;

      const lastValue =
        lastValuesCollectionChain.sumBy(s => s.totalCost).value() || 0;

      return {
        project: name,
        periodStart: DateTime.fromFormat(
          startDate,
          DEFAULT_DATE_FORMAT,
        ).toFormat("yyyy-'Q'q"),
        periodEnd: DateTime.fromFormat(
          inclusiveEndDate,
          DEFAULT_DATE_FORMAT,
        ).toFormat("yyyy-'Q'q"),
        aggregation: [firstValue, lastValue],
        change: changeOfNumbers(firstValue, lastValue),
        products: [
          {
            id: 'Compute Engine',
            aggregation: [
              firstValuesCollectionChain
                .sumBy(s => s.cpuCost + s.ramCost)
                .value() || 0,
              lastValuesCollectionChain
                .sumBy(s => s.cpuCost + s.ramCost)
                .value() || 0,
            ],
          },
          {
            id: 'Cloud Dataflow',
            aggregation: [
              firstValuesCollectionChain
                .sumBy(
                  s =>
                    s.networkCost +
                    s.sharedCost +
                    s.loadBalancerCost +
                    s.externalCost,
                )
                .value() || 0,
              lastValuesCollectionChain
                .sumBy(
                  s =>
                    s.networkCost +
                    s.sharedCost +
                    s.loadBalancerCost +
                    s.externalCost,
                )
                .value() || 0,
            ],
          },
          {
            id: 'Cloud Storage',
            aggregation: [
              firstValuesCollectionChain.sumBy(s => s.pvCost).value() || 0,
              lastValuesCollectionChain.sumBy(s => s.pvCost).value() || 0,
            ],
          },
        ],
      } as ProjectGrowthData;
    });

    return [
      ...projectGrowthDatas.map(
        projectGrowthData => new ProjectGrowthAlert(projectGrowthData),
      ),
    ];
  }
}
