// https://github.com/backstage/backstage/blob/master/plugins/cost-insights/src/utils/assert.ts
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return !isNull(value) && !isUndefined(value);
}

export function isUndefined(value: any): value is undefined {
  return value === undefined;
}

export function isNull(value: any): boolean {
  return value === null;
}

// Utility for exhaustiveness checking in switch statements
export function assertNever(x: never): never {
  throw new Error(`Exhaustiveness check failed: ${x}`);
}

export function assertAlways<T>(argument: T | undefined): T {
  if (argument === undefined) {
    throw new TypeError(
      'Expected to always find a value but received undefined',
    );
  }
  return argument;
}

// Utility for working with static lists; asserts a value will always be found or
// throws an error
export function findAlways<T>(
  collection: T[],
  callback: (el: T) => boolean,
): T {
  return assertAlways(collection.find(callback));
}

export function findAnyKey<T>(
  record: Record<string, T> | undefined,
): string | undefined {
  return Object.keys(record ?? {}).find(_ => true);
}
