import {
  DEFAULT_DATE_FORMAT,
  Duration,
} from '@backstage-community/plugin-cost-insights';
import {
  DateAggregation,
  ChangeStatistic,
  Trendline,
  Entity as CostEntity,
} from '@backstage-community/plugin-cost-insights-common';
import {
  OpenCostPV,
  OpenCostSummaryStep,
} from '@internal/plugin-cheetah-common';
import _ from 'lodash';
import { DateTime } from 'luxon';
import regression, { DataPoint } from 'regression';
import { inclusiveEndDateOf, inclusiveStartDateOf } from './duration';

type IntervalFields = {
  duration: Duration;
  endDate: string;
};

export function parseIntervals(intervals: string): IntervalFields {
  const match = intervals.match(
    /\/(?<duration>P\d+[DM])\/(?<date>\d{4}-\d{2}-\d{2})/,
  );
  if (Object.keys(match?.groups || {}).length !== 2) {
    throw new Error(`Invalid intervals: ${intervals}`);
  }
  const { duration, date } = match!.groups!;
  return {
    duration: duration as Duration,
    endDate: date,
  };
}

export function changeOfNumbers(
  firstAmount: number,
  lastAmount: number,
): ChangeStatistic {
  // if either the first or last amounts are zero, the rate of increase/decrease is infinite
  if (!firstAmount || !lastAmount) {
    return {
      amount: lastAmount - firstAmount,
    };
  }

  return {
    ratio: (lastAmount - firstAmount) / firstAmount,
    amount: lastAmount - firstAmount,
  };
}

export function changeOf(aggregation: DateAggregation[]): ChangeStatistic {
  const firstAmount = aggregation.length ? aggregation[0].amount : 0;
  const lastAmount = aggregation.length
    ? aggregation[aggregation.length - 1].amount
    : 0;
  return changeOfNumbers(firstAmount, lastAmount);
}

export function trendlineOf(aggregation: DateAggregation[]): Trendline {
  const data: ReadonlyArray<DataPoint> = aggregation.map(a => [
    Date.parse(a.date) / 1000,
    a.amount,
  ]);
  const result = regression.linear(data, { precision: 5 });
  return {
    slope: result.equation[0],
    intercept: result.equation[1],
  };
}

export function parseIntervalsExtended(intervals: string) {
  const { duration, endDate } = parseIntervals(intervals);
  const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
  const days = DateTime.fromISO(endDate).diff(
    DateTime.fromISO(inclusiveStartDateOf(duration, inclusiveEndDate)),
    'days',
  ).days;

  return {
    days,
    duration,
    inclusiveEndDate,
  };
}

export function aggregationFor(
  openCostSummary: OpenCostSummaryStep[],
  intervals: string,
  accumulate: boolean,
  product?: string,
) {
  const { duration, endDate } = parseIntervals(intervals);
  const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
  const days = DateTime.fromISO(endDate).diff(
    DateTime.fromISO(inclusiveStartDateOf(duration, inclusiveEndDate)),
    'days',
  ).days;

  const aggregation = [...Array(days).keys()].reduce(
    (values: DateAggregation[], i: number): DateAggregation[] => {
      const last = values.length ? values[values.length - 1].amount : 0;

      const date = DateTime.fromISO(
        inclusiveStartDateOf(duration, inclusiveEndDate),
      )
        .plus({ days: i })
        .toFormat(DEFAULT_DATE_FORMAT);
      const podSummaryForDate = openCostSummary.find(
        os => DateTime.fromISO(os.date).toFormat(DEFAULT_DATE_FORMAT) === date,
      );

      const nextStorageCostDelta =
        _.sumBy(podSummaryForDate?.summaries, s => s.pvCost) || 0;
      const nextComputeCostDelta =
        _.sumBy(
          podSummaryForDate?.summaries,
          s => s.cpuCost + s.gpuCost + s.ramCost,
        ) || 0;
      const nextOtherCostDelta =
        _.sumBy(
          podSummaryForDate?.summaries,
          s =>
            s.networkCost + s.sharedCost + s.loadBalancerCost + s.externalCost,
        ) || 0;

      let nextDelta =
        nextStorageCostDelta + nextComputeCostDelta + nextOtherCostDelta;
      if (product) {
        switch (product) {
          case 'computeEngine':
            nextDelta = nextComputeCostDelta;
            break;
          case 'cloudStorage':
            nextDelta = nextStorageCostDelta;
            break;
          case 'other':
            nextDelta = nextOtherCostDelta;
            break;

          default:
            throw new Error(
              `Cannot get insights for ${product}. Make sure product matches product property in app-info.yaml`,
            );
        }
      }
      let amount = nextDelta;
      if (accumulate) {
        amount = Math.max(0, last + nextDelta);
      }

      values.push({
        date: date,
        amount: amount,
      });
      return values;
    },
    [],
  );

  return aggregation;
}

function getPvCostForBucket(
  bucket: OpenCostSummaryStep[],
  nodeName: string,
  persistentVolumeName: string,
) {
  return _.sumBy(bucket, b =>
    _.sumBy(
      b.summaries
        .filter(summary => summary.name === nodeName)
        .filter(summary => summary.pvs?.[persistentVolumeName])
        .flatMap(summary => summary.pvs[persistentVolumeName] as OpenCostPV),
      s => s?.cost,
    ),
  );
}

function getStorageCostCostForBucket(
  bucket: OpenCostSummaryStep[],
  nodeName?: string,
) {
  return _.sumBy(bucket, b =>
    _.sumBy(
      b.summaries.filter(summary =>
        nodeName ? summary.name === nodeName : true,
      ),
      s => s.pvCost,
    ),
  );
}

function getComputeCostCostForBucket(
  bucket: OpenCostSummaryStep[],
  nodeName?: string,
) {
  return _.sumBy(bucket, b =>
    _.sumBy(
      b.summaries.filter(summary =>
        nodeName ? summary.name === nodeName : true,
      ),
      s => s.cpuCost + s.ramCost,
    ),
  );
}

function getCloudStorageInsights(buckets: OpenCostSummaryStep[][]): CostEntity {
  const allSummariesFlat = buckets.flatMap(bucket =>
    bucket.flatMap(dateSummary => dateSummary.summaries),
  );
  const uniqueNodeNames = _.uniqBy(allSummariesFlat, n => n.name);

  const firstAmount = getStorageCostCostForBucket(buckets[0]);
  const lastAmount = getStorageCostCostForBucket(buckets[1]);

  const cloudStorageCost = {
    id: 'cloudStorage',
    aggregation: [firstAmount, lastAmount],
    change: changeOfNumbers(firstAmount, lastAmount),
    entities: {
      namespaces: uniqueNodeNames.map(nodeSummary => {
        const nodeFirstAmount = getStorageCostCostForBucket(
          buckets[0],
          nodeSummary.name,
        );
        const nodeLastAmount = getStorageCostCostForBucket(
          buckets[1],
          nodeSummary.name,
        );

        const persistentVolumesForNode = _.chain(allSummariesFlat)
          .filter(summary => summary.name === nodeSummary.name)
          .filter(summary => summary.pvs)
          .flatMap(summary => Object.keys(summary.pvs))
          .uniq()
          .value();

        return {
          id: nodeSummary.name || 'Unattached',
          aggregation: [nodeFirstAmount, nodeLastAmount],
          change: changeOfNumbers(nodeFirstAmount, nodeLastAmount),
          entities: {
            PVS: persistentVolumesForNode.map(persistentVolumeName => {
              const pvcFirstAmount = getPvCostForBucket(
                buckets[0],
                nodeSummary.name,
                persistentVolumeName,
              );
              const pvcLastAmount = getPvCostForBucket(
                buckets[1],
                nodeSummary.name,
                persistentVolumeName,
              );

              return {
                id: persistentVolumeName || 'Unlabelled',
                aggregation: [pvcFirstAmount, pvcLastAmount],
                change: changeOfNumbers(pvcFirstAmount, pvcLastAmount),
                entities: {},
              } as CostEntity;
            }),
          },
        } as CostEntity;
      }),
    },
  } as CostEntity;
  return cloudStorageCost;
}

function getComputeEngineInsights(
  buckets: OpenCostSummaryStep[][],
): CostEntity {
  const uniqueNodes = buckets.flatMap(bucket =>
    bucket.flatMap(dateSummary => dateSummary.summaries.filter(s => s.name)),
  );
  const uniqueNodeNames = _.uniqBy(uniqueNodes, n => n.name);

  const firstAmount = getComputeCostCostForBucket(buckets[0]);
  const lastAmount = getComputeCostCostForBucket(buckets[1]);

  return {
    id: 'computeEngine',
    aggregation: [firstAmount, lastAmount],
    change: changeOfNumbers(firstAmount, lastAmount),
    entities: {
      namespaces: uniqueNodeNames.map(nodeSummary => {
        const nodeFirstAmount = getComputeCostCostForBucket(
          buckets[0],
          nodeSummary.name,
        );
        const nodeLastAmount = getComputeCostCostForBucket(
          buckets[1],
          nodeSummary.name,
        );
        return {
          id: nodeSummary.name || 'Unlabeled',
          aggregation: [nodeFirstAmount, nodeLastAmount],
          change: changeOfNumbers(nodeFirstAmount, nodeLastAmount),
          entities: {},
        } as CostEntity;
      }),
    },
  };
}

export function entityOf(
  product: string,
  buckets: OpenCostSummaryStep[][],
): CostEntity {
  switch (product) {
    case 'computeEngine':
      return getComputeEngineInsights(buckets);
    case 'cloudStorage':
      return getCloudStorageInsights(buckets);
    default:
      throw new Error(
        `Cannot get insights for ${product}. Make sure product matches product property in app-info.yaml`,
      );
  }
}
