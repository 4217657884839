import { Select } from '@backstage/core-components';
import {
  CompoundEntityRef,
  getCompoundEntityRef,
  parseEntityRef,
} from '@backstage/catalog-model';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { Box } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';

export type Props = {
  value: CompoundEntityRef;
  onChange: (value: CompoundEntityRef) => void;
};

export const SelectedSystemFilter = ({ value, onChange }: Props) => {
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { error, value: systems } = useAsync(async () => {
    return await catalogApi
      .getEntities({
        filter: [
          {
            kind: ['System'],
          },
        ],
        fields: ['kind', 'metadata.name', 'metadata.namespace'],
      })
      .then(
        response =>
          response.items.map(e => getCompoundEntityRef(e)).sort() || [],
      );
  });

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: `Failed to load entity systems`,
        severity: 'error',
      });
    }
  }, [error, alertApi]);

  const handleChange = useCallback(
    (arg: string | string[] | number | number[]) =>
      onChange(parseEntityRef(String(arg))),
    [onChange],
  );

  if (!systems?.length || error) {
    return <></>;
  }

  let selected = value;
  if (!value) {
    selected = systems[0];
    onChange(selected);
  }

  return (
    <Box pb={1} pt={1}>
      <Select
        label="System"
        selected={humanizeEntityRef(selected)}
        items={systems.map(v => ({
          label: humanizeEntityRef(v),
          value: humanizeEntityRef(v),
        }))}
        onChange={handleChange}
      />
    </Box>
  );
};
