import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

/** @public */
export const CheetahUserSettingsBackstageVersionCard = () => {
  const configApi = useApi(configApiRef);
  const version = configApi.getOptionalString('app.version') ?? '{app.version}';

  return (
    <InfoCard title="Cheetah Backstage Version" variant="gridItem">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Version: {version}
          </Typography>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
