import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useBackstageCatalogData } from '../../hooks/backstageCatalogData';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

type CheetahKubernetesVariablesPickerData = {
  namespace: string;
  id: string;
  component: string;
};

export const CheetahKubernetesVariablesPicker = (
  props: FieldExtensionComponentProps<CheetahKubernetesVariablesPickerData>,
): React.ReactElement => {
  const {
    onChange = () => {}, // Default to a no-op function if onChange is undefined
    rawErrors = [], // Default to an empty array if rawErrors is undefined
    formData = { namespace: undefined, id: undefined, component: undefined }, // Default object
    formContext = {}, // Default to an empty object if formContext is undefined
  } = props;
  // Collect data from Backstage about all components
  const [{ content: catalogData }] = useBackstageCatalogData();
  const [componentList, setComponentList] = useState<string[]>([]);
  const [NamespaceList, setNamespaceList] = useState<string[]>([]);
  const [idList, setIdList] = useState<string[]>([]);

  // This function makes sure, that a component, nameSpace and Id only appearers once in the UI dropdown for each of them
  const constructUniqArraysBasedOnBackstageAPI = useCallback(() => {
    let uniqComponent: string[] = [];
    let uniqNamespace: string[] = [];
    let uniqId: string[] = [];
    if (catalogData !== undefined) {
      for (const entry of catalogData) {
        uniqComponent.push(entry.componentName);
        uniqNamespace.push(entry.nameSpace);
        uniqId.push(entry.id);
      }
      uniqComponent = [...new Set(uniqComponent)];
      uniqNamespace = [...new Set(uniqNamespace)];
      uniqId = [...new Set(uniqId)];
      setComponentList(uniqComponent);
      setNamespaceList(uniqNamespace);
      setIdList(uniqId);
    }
  }, [catalogData]);

  // Collects the value of what you are "looking" for, depending on the componentName.
  const getValue = useCallback(
    (looking: string, componentName: string) => {
      if (catalogData !== undefined) {
        for (const entry of catalogData) {
          if (componentName === entry.componentName) {
            switch (looking) {
              case 'id':
                return String(entry.id); // Convert to string
              case 'nameSpace':
                return String(entry.nameSpace); // Convert to string
              case 'owner':
                return String(entry.owner); // Convert to string
              case 'system':
                return String(entry.system); // Convert to string

              default:
                return '';
            }
          }
        }
      }
      return '';
    },
    [catalogData],
  );

  // This function is being used on render.
  // The first render makes sure to set the variables, so that the UI is updated properly
  useEffect(() => {
    let componentName = '';
    constructUniqArraysBasedOnBackstageAPI();
    if (catalogData !== undefined && formData.component === undefined) {
      for (const entry of catalogData) {
        if (entry.owner === formContext.formData.team) {
          componentName = entry.componentName;
        }
      }
      onChange({
        ...formData,
        component: componentName,
        namespace: getValue('nameSpace', componentName),
        id: getValue('id', componentName),
      });
    }
  }, [
    catalogData,
    constructUniqArraysBasedOnBackstageAPI,
    formContext.formData.team,
    formData,
    getValue,
    onChange,
  ]);

  if (catalogData === undefined || formData.component === undefined) {
    return <></>;
  }

  return (
    <FormControl margin="normal" required error={rawErrors?.length > 0}>
      <Autocomplete
        value={formData.component}
        id="componentNameId"
        freeSolo
        autoSelect
        onChange={(_, value) => {
          onChange({
            ...formData,
            component: value ?? '',
            namespace: getValue('nameSpace', value ?? '') ?? '',
            id: getValue('id', value ?? '') ?? '',
          });
        }}
        options={componentList ?? []}
        renderInput={params => (
          <TextField
            {...params}
            label="Component name"
            helperText="The name of the component in catalog-info.yaml"
            required
          />
        )}
      />
      <Box sx={{ marginTop: 20 }} />
      <Autocomplete
        id="nameSpaceId"
        value={formData.namespace}
        freeSolo
        autoSelect
        onChange={
          (formData.component,
          (_, value) => {
            onChange({
              ...formData,
              namespace: value ?? '',
            });
          })
        }
        options={NamespaceList ?? []}
        renderInput={params => (
          <TextField
            {...params}
            label="Namespace"
            helperText="Kubernetes namespace"
            required
          />
        )}
      />
      <Box sx={{ marginTop: 20 }} />
      <Autocomplete
        value={formData.id}
        id="componentId"
        freeSolo
        autoSelect
        onChange={
          (formData.component,
          (_, value) => {
            onChange({
              ...formData,
              id: value ?? '',
            });
          })
        }
        options={idList ?? []}
        renderInput={params => (
          <TextField
            {...params}
            label="Id"
            helperText="Kubernetes id"
            required
          />
        )}
      />
    </FormControl>
  );
};
