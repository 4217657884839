import { createCardExtension } from '@backstage/plugin-home-react';
import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import {
  ColumnBreakpoints,
  CustomLink,
} from './components/Cards/HomePageLinks/types';
import { catalogPlugin } from '@backstage/plugin-catalog';
import {
  DependsOnGenericCardProps,
  DependsOnTopicsCardProps,
} from './components/ComponentCards';
import { HomePageGithubIssuesContentProps } from './components/Cards/HomePageGithubIssues/types';
import { DependabotAlertsTableContentProps } from './components/Cards/DependabotAlerts/types';
import { LatestWorkflowRunsCardContentProps } from './components/Cards/HomePageGithubWorkflows/types';
import { DefectdojoVulnerabilitiesTableContentProps } from './components/Cards/DefectdojoVulnerabilities/types';
import { ReleaseRSSFeedCardContentProps } from './components/Cards/ReleaseRSSFeed/types';

/** @public */
export const cheetahPlugin = createPlugin({
  id: 'cheetah',
  // TODO: routes?
});

export const HomePageMyTeams = cheetahPlugin.provide(
  createCardExtension<{
    title?: string;
  }>({
    name: 'MyTeams',
    title: 'My Teams Card',
    components: () => import('./components/Cards/HomePageMyTeams'),
  }),
);

/** @public */
export const DependsOnTopicsCard: (
  props: DependsOnTopicsCardProps,
) => React.JSX.Element = catalogPlugin.provide(
  createComponentExtension({
    name: 'ConsumingTopicsCard',
    component: {
      lazy: () =>
        import('./components/ComponentCards/DependsOnTopicsCard').then(
          m => m.DependsOnTopicsCard,
        ),
    },
  }),
);

export const DependsOnGenericCard: (
  props: DependsOnGenericCardProps,
) => React.JSX.Element = catalogPlugin.provide(
  createComponentExtension({
    name: 'DependsOnGenericCard',
    component: {
      lazy: () =>
        import('./components/ComponentCards/DependsOnGenericCard').then(
          m => m.DependsOnGenericCard,
        ),
    },
  }),
);

export const HomePageOwnershipCard = cheetahPlugin.provide(
  createCardExtension<{
    title?: string;
  }>({
    name: 'HomepageOwnershipCard',
    title: 'HomepageOwnershipCard',
    components: () => import('./components/Cards/HomepageOwnershipCard'),
  }),
);

export const HomePageLinks = cheetahPlugin.provide(
  createCardExtension<{
    title?: string;
    cols?: ColumnBreakpoints | number;
    links: Array<CustomLink>;
  }>({
    name: 'MyLinks',
    title: 'My Links Card',
    components: () => import('./components/Cards/HomePageLinks'),
  }),
);

export const HomePageAlerts = cheetahPlugin.provide(
  createCardExtension<{
    title?: string;
  }>({
    name: 'Alerts',
    title: 'My Alerts Card',
    components: () => import('./components/Cards/HomePageAlerts'),
  }),
);

export const HomePageRSSItem = cheetahPlugin.provide(
  createCardExtension<{
    title?: string;
    itemProperty: string;
    shouldLink: boolean;
    feedURL: string;
  }>({
    name: 'HomePageRSSItem',
    title: 'My RSS Card',
    components: () => import('./components/Cards/HomePageRSSItem'),
  }),
);

export const HomePageJSONItem = cheetahPlugin.provide(
  createCardExtension<{
    linkSelector: string;
    titleSelector: string;
    descriptionSelector: string;
    jsonUrl: string;
    title?: string;
    shouldLink: boolean;
    arraySelector: string;
  }>({
    name: 'HomePageJSONItem',
    title: 'Show JSON items from url',
    components: () => import('./components/Cards/HomePageJSONItem'),
  }),
);

export const HomePageGithubIssues = cheetahPlugin.provide(
  createCardExtension<HomePageGithubIssuesContentProps>({
    name: 'HomePageGithubIssues',
    title: 'Show Github issues',
    components: () => import('./components/Cards/HomePageGithubIssues'),
  }),
);

export const DependabotAlerts = cheetahPlugin.provide(
  createCardExtension<DependabotAlertsTableContentProps>({
    name: 'DependabotAlerts',
    title: 'Show Github DependabotAlerts',
    components: () => import('./components/Cards/DependabotAlerts'),
  }),
);

export const DefectdojoVulnerabilities = cheetahPlugin.provide(
  createCardExtension<DefectdojoVulnerabilitiesTableContentProps>({
    name: 'DefectdojoVulnerabilities',
    title: 'Show CI/CD Vulnerabilities',
    components: () => import('./components/Cards/DefectdojoVulnerabilities'),
  }),
);

export const LatestGithubWorkflowRuns = cheetahPlugin.provide(
  createCardExtension<LatestWorkflowRunsCardContentProps>({
    name: 'LatestGithubWorkflowRuns',
    title: 'Show Github Latest Workflow Runs',
    components: () => import('./components/Cards/HomePageGithubWorkflows'),
  }),
);

export const ReleaseRSSFeed = cheetahPlugin.provide(
  createCardExtension<ReleaseRSSFeedCardContentProps>({
    name: 'ReleaseRSSFeed',
    title: 'Show latest release in an RSS feed',
    components: () => import('./components/Cards/ReleaseRSSFeed'),
  }),
);
