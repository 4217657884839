/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { Select, SelectItem } from '@backstage/core-components';
import { RepoUrlPickerState } from './types';
import { RepoUrlPickerRepoName } from './RepoUrlPickerRepoName';
import { useGitlabRepositories } from '../../hooks/gitlabRepositories';
import { LinearProgress } from '@material-ui/core';

export const GitlabRepoPicker = (props: {
  allowedOwners?: string[];
  repoPattern?: string;
  rawErrors: string[];
  state: RepoUrlPickerState;
  team: string;
  onChange: (state: RepoUrlPickerState) => void;
}) => {
  const {
    allowedOwners = [],
    state,
    onChange,
    rawErrors,
    repoPattern,
    team,
  } = props;
  const ownerItems: SelectItem[] = allowedOwners
    ? allowedOwners.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  const { owner } = state;

  // TODO KAMDP-321: implement GitLab repos fetching in the backend
  const [{ availableRepos, loading: availableReposLoading }] =
    useGitlabRepositories(repoPattern ?? '.*');
  if (availableReposLoading || !availableRepos) {
    return <LinearProgress />;
  }

  // Sort the result from the Gitlab api
  // Returns string[] with only the repo names from gitlab
  // Returns empty string[] if availableRepos is undefined

  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !owner}
      >
        {allowedOwners?.length ? (
          <Select
            native
            label="Owner Available"
            onChange={selected =>
              onChange({
                owner: String(Array.isArray(selected) ? selected[0] : selected),
              })
            }
            disabled={allowedOwners.length === 1}
            selected={owner}
            items={ownerItems}
          />
        ) : (
          <>
            <InputLabel htmlFor="ownerInput">Owner</InputLabel>
            <Input
              id="ownerInput"
              onChange={e => onChange({ owner: e.target.value })}
              value={owner}
            />
          </>
        )}
        <FormHelperText>
          GitLab namespace where this repository will belong to. It can be the
          name of organization, group, subgroup, user, or the project.
        </FormHelperText>
      </FormControl>
      <RepoUrlPickerRepoName
        state={state}
        team={team}
        onChange={repo => onChange({ repoName: repo })}
        rawErrors={rawErrors}
        loading={availableReposLoading}
        availableRepos={availableRepos?.map(x => x.name)}
      />
    </>
  );
};
