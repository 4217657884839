import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { KamstrupIcon } from '../../assets/customIcons';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarScrollWrapper,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import {
  configApiRef,
  IconComponent,
  useApi,
} from '@backstage/core-plugin-api';

// https://github.com/jonathan-ash/backstage/blob/master/packages/core-components/src/layout/Sidebar/SidebarSubmenuItem.tsx
const useSidebarLogoStyles = makeStyles(theme => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  itemContainer: {
    width: '100%',
  },
  item: {
    height: 48,
    width: '100%',
    '&:hover': {
      background: '#6f6f6f',
      color: theme.palette.navigation.selectedColor,
    },
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.navigation.color,
    padding: 20,
    cursor: 'pointer',
    position: 'relative',
    background: 'none',
    border: 'none',
  },
  label: {
    margin: 14,
    marginLeft: 7,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
}));

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  const configApi = useApi(configApiRef);
  const logoName = configApi.getOptionalString('app.logoName');

  let myLogo;
  if (logoName === 'kamstrup') {
    myLogo = <KamstrupIcon />;
  } else {
    myLogo = isOpen ? <LogoFull /> : <LogoIcon />;
  }

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {myLogo}
      </Link>
    </div>
  );
};

export type SidebarExternalLinkSubmenuItemProps = {
  title: string;
  href?: string;
  icon?: IconComponent;
};

// const SideBarExternalLink = (props: SidebarExternalLinkSubmenuItemProps) => {
//   const { title, href, icon: Icon } = props;
//   const classes = useSidebarLogoStyles();

//   return (
//     <div className={classes.itemContainer}>
//       <Tooltip title={title} enterDelay={500} enterNextDelay={500}>
//         <MaterialLink
//           href={href}
//           target="_blank"
//           underline="none"
//           className={classes.item}
//         >
//           {Icon && <Icon fontSize="small" />}
//           <Typography variant="subtitle1" className={classes.label}>
//             {title}
//             <br />
//           </Typography>
//         </MaterialLink>
//       </Tooltip>
//     </div>
//   );
// };

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const configApi = useApi(configApiRef);
  const showStandardApps =
    configApi.getOptionalBoolean('cheetah.showStandardApps') || false;

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Service discovery */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
          <SidebarItem
            icon={HomeIcon}
            // to="catalog?filters%5Btags%5D=deployed"
            to="deployed?filters%5Btags%5D=deployed"
            text="Deployed"
          />
          <SidebarItem icon={GroupWorkIcon} to="systems" text="Systems" />
          {/*     <SidebarItem
            icon={AccountTree}
            to="catalog-graph"
            text="Catalog graph"
          />
          <SidebarItem
            icon={GraphicEqIcon}
            to="catalog-graph"
            text="Catalog graph"
          /> */}
          <SidebarDivider />
          {/* Cheetah */}
          <SidebarItem
            icon={BuildIcon}
            to="infrastructure?filters%5Bkind%5D=platform"
            // to="infrastructure"
            text="Infrastructure"
          />

          {showStandardApps && (
            <SidebarItem
              icon={GetAppIcon}
              to="standard-apps?filters%5Bkind%5D=platform"
              // to="infrastructure"
              text="Standard Apps"
            />
          )}

          <SidebarDivider />
          {/* Data discovery */}
          {/* <SidebarItem icon={DeviceHubIcon} to="resources" text="Resources" /> */}
          <SidebarItem icon={ExtensionIcon} to="api-docs" text="WebAPIs" />
          <SidebarItem
            icon={CalendarViewDayIcon}
            to="resources?filters%5Btype%5D=kafkatopic"
            text="Topics"
          />
          <SidebarItem
            icon={AssignmentIcon}
            to="resources?filters%5Btype%5D=opensearch-index"
            text="Indices"
          />

          <SidebarDivider />
          {/* meta */}
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarDivider />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          <SidebarDivider />
          {/* <SidebarScrollWrapper>
              <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
            </SidebarScrollWrapper> */}
          <SidebarScrollWrapper>
            {configApi.getBoolean('costInsights.enabled') && (
              <SidebarItem
                icon={MoneyIcon}
                to="cost-insights"
                text="Cost Insights"
              />
            )}
            <SidebarItem
              icon={GraphicEqIcon}
              to="cheetah-catalog"
              text="System overview"
            />
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
