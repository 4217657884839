import { Grid } from '@material-ui/core';
import React from 'react';
import { ConsumerGroupOffsets } from './ConsumerGroupOffsets/ConsumerGroupOffsets';
import { useConsumerGroupsOffsetsForEntity } from './ConsumerGroupOffsets/useConsumerGroupsOffsetsForEntity';
import { ProducerTopicOffsets } from './ProducerTopics/ProducerTopics';
import { useProducerTopicsOffsetsForEntity } from './ProducerTopics/useProducerTopicsOffsetsForEntity';
import { useApi } from '@backstage/core-plugin-api';
import { TableTopicInfo } from '../lib/types';
import { kafkaDashboardApiRef, TopicPartitionInfo } from '../api/types';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import {
  CHEETAH_CUSTOM_TYPES_TOPIC,
  CheetahKafkaDashboardClient,
  extractTopicVariants,
} from '@internal/plugin-cheetah-common';

export const KafkaTopicsForConsumer = () => {
  const [tableProps, { retry }] = useConsumerGroupsOffsetsForEntity();
  const [tableProps2] = useProducerTopicsOffsetsForEntity();

  const { entity } = useEntity();

  const apiDashboard = useApi(kafkaDashboardApiRef);
  const cheetahKafkaDashboardClient = new CheetahKafkaDashboardClient({
    kafkaDashboardApi: apiDashboard,
  });

  return (
    <Grid>
      <>
        {tableProps.consumerGroupsTopics?.map(consumerGroup => {
          return (
            <ConsumerGroupOffsets
              clusterId={consumerGroup.clusterId}
              consumerGroupUrl={
                cheetahKafkaDashboardClient.getConsumerGroupUrl(
                  consumerGroup.clusterId,
                  consumerGroup.consumerGroup,
                  entity,
                ).url
              }
              consumerGroup={consumerGroup.consumerGroup}
              topics={consumerGroup.topics
                .filter(topic => filterTopics(topic, entity))
                .map(topic => {
                  return toTableTopicInfo(
                    cheetahKafkaDashboardClient,
                    consumerGroup.clusterId,
                    consumerGroup.consumerGroup,
                    entity,
                    topic,
                  );
                })}
              loading={tableProps.loading}
              retry={retry}
            />
          );
        })}
      </>
      <>
        {tableProps2.producerGroupsTopics?.map(producerGroup => {
          return (
            <ProducerTopicOffsets
              clusterId={producerGroup.clusterId}
              consumerGroup={producerGroup.consumerGroup}
              topics={producerGroup.topics
                .filter(topic => filterTopics(topic, entity))
                .map(topic => {
                  return toTableTopicInfo(
                    cheetahKafkaDashboardClient,
                    producerGroup.clusterId,
                    producerGroup.consumerGroup,
                    entity,
                    topic,
                  );
                })}
              loading={tableProps2.loading}
              retry={retry}
            />
          );
        })}
      </>
    </Grid>
  );
};

function filterTopics(topicInfo: TopicPartitionInfo, entity: Entity): boolean {
  return entity.spec?.type === CHEETAH_CUSTOM_TYPES_TOPIC
    ? extractTopicVariants(entity.metadata.name, topicInfo.topic)
    : true;
}

function toTableTopicInfo(
  cheetahKafkaDashboardClient: CheetahKafkaDashboardClient,
  clusterId: string,
  consumerGroup: string,
  entity: Entity,
  topicInfo: TopicPartitionInfo,
): TableTopicInfo {
  if (
    cheetahKafkaDashboardClient.hasDashboardUrl(
      clusterId,
      consumerGroup,
      entity,
    )
  ) {
    return {
      ...topicInfo,
      hasLink: true,
      link: cheetahKafkaDashboardClient.getTopicUrl(
        clusterId,
        consumerGroup,
        entity,
        topicInfo.topic,
      ).url,
    } as TableTopicInfo;
  }
  return { ...topicInfo, hasLink: false } as TableTopicInfo;
}
