import {
  RELATION_OWNER_OF,
  RELATION_OWNED_BY,
  RELATION_CONSUMES_API,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_PROVIDES_API,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PARENT_OF,
  RELATION_CHILD_OF,
  RELATION_HAS_MEMBER,
  RELATION_MEMBER_OF,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
} from '@backstage/catalog-model';

export const CHEETAH_CUSTOM_TYPES_FLINK = 'flinkjob';
export const CHEETAH_CUSTOM_TYPES_INFRASTRUCTURE = 'infrastructure';
export const CHEETAH_CUSTOM_TYPES_TOPIC = 'kafkatopic';

/**
 * A relation of an TOPIC being consumed, typically by a component. Reversed direction of
 * {@link RELATION_CONSUMES_TOPIC}.
 *
 * @public
 */
export const RELATION_TOPIC_CONSUMED_BY = 'topicConsumedBy';

/**
 * A relation with an TOPIC entity, typically from a component. Reversed direction of
 * {@link RELATION_TOPIC_CONSUMED_BY}.
 *
 * @public
 */
export const RELATION_CONSUMES_TOPIC = 'consumesTopic';

/**
 * A relation from an TOPIC to its provider entity (typically a component). Reversed direction of
 * {@link RELATION_PROVIDES_TOPIC}.
 *
 * @public
 */
export const RELATION_TOPIC_PROVIDED_BY = 'topicProvidedBy';

/**
 * A relation from an TOPIC provider entity (typically a component) to the TOPIC. Reversed direction of
 * {@link RELATION_TOPIC_PROVIDED_BY}.
 *
 * @public
 */
export const RELATION_PROVIDES_TOPIC = 'providesTopic';

/**
 * A pair of two relations that describe the opposite of each other. The first
 * relation is considered as the primary relation.
 *
 * @public
 */
export type RelationPairs = [string, string][];

/**
 * A list of pairs of entity relations, used to define which relations are
 * merged together and which the primary relation is.
 *
 * @public
 */
// https://github.com/backstage/backstage/blob/master/plugins/catalog-graph/src/components/EntityRelationsGraph/relations.ts
export const ALL_CHEETAH_RELATION_PAIRS: RelationPairs = [
  [RELATION_OWNER_OF, RELATION_OWNED_BY],
  [RELATION_CONSUMES_API, RELATION_API_CONSUMED_BY],
  [RELATION_API_PROVIDED_BY, RELATION_PROVIDES_API],
  [RELATION_HAS_PART, RELATION_PART_OF],
  [RELATION_PARENT_OF, RELATION_CHILD_OF],
  [RELATION_HAS_MEMBER, RELATION_MEMBER_OF],
  [RELATION_DEPENDS_ON, RELATION_DEPENDENCY_OF],
  // new / custom
  [RELATION_TOPIC_CONSUMED_BY, RELATION_CONSUMES_TOPIC],
  [RELATION_TOPIC_PROVIDED_BY, RELATION_PROVIDES_TOPIC],
];

export interface FluxEventInvolvedObject {
  apiVersion: string;
  kind: string;
  name: string;
  namespace: string;
  uid: string;
}

// https://github.com/fluxcd/pkg/blob/main/apis/event/v1beta1/event.go
export interface FluxEvent {
  involvedObject: FluxEventInvolvedObject;
  metadata: string;
  severity: 'info' | 'error' | 'trace';
  reason: string;
  message: string;
  reportingController: string;
  timestamp: string;
}

export interface Stat {
  displayName: string;
  unit: string;
  value: number;
}

export interface LokiLineBase {
  level: string;
  ts: string;
  msg: string;
  controller: string;
  ['reconciler kind']: string;
  controllerGroup: string;
  controllerKind: string;
  HelmRelease: string;
  namespace: string;
  name: string;
  reconcileID: string;
  output: string;
  logger: string;
  Kustomization: string;
  revision: string;
  error: string;
}

export interface LokiLineInfo extends LokiLineBase {
  level: 'info';
}

export interface LokiLineError extends LokiLineBase {
  level: 'error';
  error: string;
}

export type LokiLine = LokiLineInfo | LokiLineError;

export interface FluxStatus {
  status: 'ok' | 'error';
  message: string;
  error?: string;
}

export interface LokiResponseValue {
  timestamp: string;
  logLine: string;
}
