import {
  parseEntityRef,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import {
  RELATION_TOPIC_CONSUMED_BY,
  RELATION_TOPIC_PROVIDED_BY,
} from '@internal/plugin-cheetah-common';
import _ from 'lodash';

export function getRelationDisplayText(relType: string, prefix?: string) {
  switch (relType) {
    case RELATION_DEPENDS_ON:
      return `${prefix ? prefix : ''}uses`;
    case RELATION_DEPENDENCY_OF:
      return `${prefix ? prefix : ''}uses`;
    case RELATION_TOPIC_CONSUMED_BY:
      return `${prefix ? prefix : ''}consumes`;
    case RELATION_TOPIC_PROVIDED_BY:
      return `${prefix ? prefix : ''}produces`;
    case RELATION_HAS_PART:
      return '';
    default:
      return (prefix ? prefix : '') + relType;
  }
}

export function normalizeEntityRefName(entityRef: string) {
  return _.snakeCase(humanizeEntityRef(parseEntityRef(entityRef)));
}
