import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  PreviewValueField,
  PreviewValueFieldUiOptions,
} from './PreviewValueField';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export const PreviewValueFieldFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension<string, PreviewValueFieldUiOptions>({
    name: 'PreviewValue',
    component: PreviewValueField as any,
  }),
);
