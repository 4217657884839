import {
  DEFAULT_DATE_FORMAT,
  Duration,
} from '@backstage-community/plugin-cost-insights';
import { DateTime, Duration as LuxonDuration } from 'luxon';
import { assertNever } from './assert';

export const DEFAULT_DURATION = Duration.P30D;

/**
 * Derive the start date of a given period, assuming two repeating intervals.
 *
 * @param duration - see comment on Duration enum
 * @param inclusiveEndDate - from CostInsightsApi.getLastCompleteBillingDate
 */
export function inclusiveStartDateOf(
  duration: Duration,
  inclusiveEndDate: string,
): string {
  switch (duration) {
    case Duration.P7D:
    case Duration.P30D:
    case Duration.P90D:
      return DateTime.fromISO(inclusiveEndDate)
        .minus(
          LuxonDuration.fromISO(duration).plus(LuxonDuration.fromISO(duration)),
        )
        .toFormat(DEFAULT_DATE_FORMAT);
    case Duration.P3M:
      return DateTime.fromISO(inclusiveEndDate)
        .startOf('quarter')
        .minus(
          LuxonDuration.fromISO(duration).plus(LuxonDuration.fromISO(duration)),
        )
        .toFormat(DEFAULT_DATE_FORMAT);
    default:
      return assertNever(duration);
  }
}

export function exclusiveEndDateOf(
  duration: Duration,
  inclusiveEndDate: string,
): string {
  switch (duration) {
    case Duration.P7D:
    case Duration.P30D:
    case Duration.P90D:
      return DateTime.fromISO(inclusiveEndDate)
        .plus({ days: 1 })
        .toFormat(DEFAULT_DATE_FORMAT);
    case Duration.P3M:
      return DateTime.fromISO(quarterEndDate(inclusiveEndDate))
        .plus({ days: 1 })
        .toFormat(DEFAULT_DATE_FORMAT);
    default:
      return assertNever(duration);
  }
}

export function inclusiveEndDateOf(
  duration: Duration,
  inclusiveEndDate: string,
): string {
  return DateTime.fromISO(exclusiveEndDateOf(duration, inclusiveEndDate))
    .minus({ days: 1 })
    .toFormat(DEFAULT_DATE_FORMAT);
}

// https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
export function intervalsOf(
  duration: Duration,
  inclusiveEndDate: string,
  repeating: number = 2,
) {
  return `R${repeating}/${duration}/${exclusiveEndDateOf(
    duration,
    inclusiveEndDate,
  )}`;
}

export function quarterEndDate(inclusiveEndDate: string): string {
  const endDate = DateTime.fromISO(inclusiveEndDate);
  const endOfQuarter = endDate.endOf('quarter').toFormat(DEFAULT_DATE_FORMAT);
  if (endOfQuarter === inclusiveEndDate) {
    return endDate.toFormat(DEFAULT_DATE_FORMAT);
  }
  return endDate
    .startOf('quarter')
    .minus({ days: 1 })
    .toFormat(DEFAULT_DATE_FORMAT);
}
