import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  CheetahImageTagPicker,
  CheetahImageTagPickerValidation,
} from './CheetahImageTagPicker';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

export const CheetahImageTagPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CheetahImageTagPickerExtension',
    component: CheetahImageTagPicker,
    validation: CheetahImageTagPickerValidation,
  }),
);
