import React from 'react';
import {
  SettingsLayout,
  UserSettingsAuthProviders,
  UserSettingsFeatureFlags,
} from '@backstage/plugin-user-settings';
import { CheetahUserSettingsGeneral } from './CheetahUserSettingsGeneral';

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <CheetahUserSettingsGeneral />
    </SettingsLayout.Route>
    <SettingsLayout.Route
      path="auth-providers"
      title="Authentication Providers"
    >
      <UserSettingsAuthProviders />
    </SettingsLayout.Route>
    <SettingsLayout.Route path="feature-flags" title="Feature Flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route>
  </SettingsLayout>
);
