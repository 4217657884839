import _ from 'lodash';
import {
  KUBERNETES_LABEL_SELECTOR,
  KUBERNETES_INSTANCE_ANNOTATION,
  KUBERNETES_NAMESPACE_ANNOTATION,
} from '../types';
import { Config } from '@backstage/config';

export async function asyncFilter(arr: any, predicate: any) {
  const results = await Promise.all(arr.map(predicate));

  return arr.filter((_v: any, index: any) => results[index]);
}

/**
 * Gets the intersection betweens paths in attempt to get the shared base path
 */
export function getRootPath(paths: string[], prefix: string) {
  const pathArray = paths.map(path =>
    path.replace(prefix, '').split('/').slice(0, -1).join('/'),
  ); // remove prefix, remove last value and join again
  return _.union(pathArray).join(',');
}

/**
 * Convert label to url-safe expression
 */
export function k8sLabelToGrafanaExp(
  grafanaConfig: Config | undefined,
  k8sLabel: string,
  namespace: string,
): string {
  const defaultGrafanaLabel = grafanaConfig?.getString('defaultLabel') ?? 'app';
  const labelReplacements = grafanaConfig?.getConfigArray('labelReplacements');
  const grafanaExpArr = k8sLabel.split(',');
  grafanaExpArr.push(`namespace=${namespace}`);

  const grafanaExp = grafanaExpArr
    .map(label => {
      let res = label;
      labelReplacements?.forEach(l => {
        res = res.replace(
          l.getString('sourceLabel'),
          l.getString('destinationLabel'),
        );
      });
      return res;
    })
    .map(label => {
      // urlencode to key=\"keyvalue\"
      const [key, keyvalue] = label.split('=');

      if (keyvalue) {
        return `${key}=\\"${keyvalue}\\"`;
      }
      return `${defaultGrafanaLabel}=\\"${key}\\"`;
    })
    .join(',');

  return grafanaExp;
}

export function extractTopicVariants(
  topicName: string,
  candidate: string,
): boolean {
  if (!new RegExp(`^${topicName}`, 'i').exec(candidate)) return false; // starts with check
  if (new RegExp(`^${topicName}$`, 'i').exec(candidate)) return true; // equals
  const variant = candidate.replace(new RegExp(topicName, 'i'), '');
  return /^(_|__|-)?(v?)(\d?)$/i.test(variant); // variant contains something like _v12
}

export function getFilterPermutations(
  instanceName: string,
  annotations: Record<string, string>,
): string[] {
  const filters = [] as string[];

  if (annotations?.[KUBERNETES_LABEL_SELECTOR]) {
    filters.push(`?label=${annotations?.[KUBERNETES_LABEL_SELECTOR]}`);
  }

  if (
    annotations?.[KUBERNETES_INSTANCE_ANNOTATION] &&
    annotations?.[KUBERNETES_NAMESPACE_ANNOTATION]
  ) {
    filters.push(
      `?label=app.kubernetes.io/instance=${annotations?.[KUBERNETES_INSTANCE_ANNOTATION]}&namespace=${annotations?.[KUBERNETES_NAMESPACE_ANNOTATION]}`,
    );
  }

  if (annotations?.[KUBERNETES_INSTANCE_ANNOTATION]) {
    let kubernetesFilter = `?label=${KUBERNETES_INSTANCE_ANNOTATION}=${annotations?.[KUBERNETES_INSTANCE_ANNOTATION]}`;
    if (annotations?.[KUBERNETES_NAMESPACE_ANNOTATION]) {
      const kubernetesNamespaceFilter = `${KUBERNETES_NAMESPACE_ANNOTATION}=${annotations?.[KUBERNETES_NAMESPACE_ANNOTATION]}`;
      kubernetesFilter = `${kubernetesFilter},${kubernetesNamespaceFilter}`;
    }
    filters.push(kubernetesFilter);
  }

  // filters.push(`?label=app.kubernetes.io/instance=${instanceName}`);
  filters.push(`?label=app=${instanceName}`);

  // filters.push(`?label=app.kubernetes.io/name=${instanceName}`);

  return filters.map(filter => filter.replace('/', '%2F')); // urlencode
}
