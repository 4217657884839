// https://github.com/backstage/backstage/blob/master/plugins/catalog-graph/src/components/CatalogGraphPage/CatalogGraphPage.tsx

import {
  CompoundEntityRef,
  parseEntityRef,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  Content,
  ContentHeader,
  DependencyGraphTypes,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { catalogApiRef, entityRouteRef } from '@backstage/plugin-catalog-react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import React, { useCallback, useEffect, MouseEvent } from 'react';
import {
  Direction,
  EntityRelationsGraph,
  RelationPairs,
  EntityNode,
} from '@backstage/plugin-catalog-graph';
import { SelectedSystemFilter } from './SelectedSystemFilter';
import { useCheetahCatalogGraphPage } from './useCheetahCatalogGraphPage';
import { CustomLabel } from './CustomLabel';
import { CustomNode } from './CustomNode';
import { useApi, alertApiRef, useRouteRef } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  ALL_CHEETAH_RELATION_PAIRS,
  CHEETAH_CUSTOM_TYPES_FLINK,
  CHEETAH_CUSTOM_TYPES_TOPIC,
  RELATION_CONSUMES_TOPIC,
  RELATION_PROVIDES_TOPIC,
  RELATION_TOPIC_CONSUMED_BY,
  RELATION_TOPIC_PROVIDED_BY,
} from '@internal/plugin-cheetah-common';
import { DirectionFilter } from './internal/DirectionFilter';
import { MaxDepthFilter } from './internal/MaxDepthFilter';
import { useNavigate } from 'react-router';
import { ExportToPlantuml } from './ExportToPlantuml';
import { SwitchFilter } from './internal/SwitchFilter';

const useStyles = makeStyles(
  theme => ({
    content: {
      minHeight: 0,
    },
    container: {
      height: '100%',
      maxHeight: '100%',
      minHeight: 0,
    },
    fullHeight: {
      maxHeight: '100%',
      display: 'flex',
      minHeight: 0,
    },
    graphWrapper: {
      position: 'relative',
      flex: 1,
      minHeight: 0,
      display: 'flex',
    },
    graph: {
      flex: 1,
      minHeight: 0,
    },
    legend: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: theme.spacing(1),
      '& .icon': {
        verticalAlign: 'bottom',
      },
    },
    filters: {
      display: 'grid',
      gridGap: theme.spacing(1),
      gridAutoRows: 'auto',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
      [theme.breakpoints.only('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
  }),
  { name: 'PluginCatalogGraphCatalogGraphPage' },
);

// fix platypus group
export const CheetahCatalogGraphPage = (props: {
  relationPairs?: RelationPairs;
  rootEntityRefs?: string[];
}) => {
  const { relationPairs = ALL_CHEETAH_RELATION_PAIRS } = props;
  const selectedKinds = [
    'component',
    // 'system',
    'api',
    //  'group',
    // 'platform',
    'resource',
  ];
  const showFilters = true;

  const selectedRelations = [
    RELATION_OWNER_OF,
    RELATION_OWNED_BY,
    RELATION_CONSUMES_API,
    RELATION_API_CONSUMED_BY,
    RELATION_PROVIDES_API,
    RELATION_API_PROVIDED_BY,
    RELATION_HAS_PART,
    RELATION_PART_OF,
    RELATION_DEPENDS_ON,
    RELATION_DEPENDENCY_OF,
    RELATION_TOPIC_CONSUMED_BY,
    RELATION_CONSUMES_TOPIC,
    RELATION_TOPIC_PROVIDED_BY,
    RELATION_PROVIDES_TOPIC,
  ];

  const navigate = useNavigate();
  const classes = useStyles();
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const onNodeClick = useCallback(
    (node: EntityNode, _event: MouseEvent<unknown>) => {
      const nodeEntityName = parseEntityRef(node.id);

      const path = catalogEntityRoute({
        kind: nodeEntityName.kind.toLocaleLowerCase('en-US'),
        namespace: nodeEntityName.namespace.toLocaleLowerCase('en-US'),
        name: nodeEntityName.name,
      });

      navigate(path);
    },
    [catalogEntityRoute, navigate],
  );

  const { error: topicsError, value: kafkatopics } = useAsync(async () => {
    return await catalogApi
      .getEntities({
        filter: [
          {
            kind: ['Resource'],
            ['spec.type']: CHEETAH_CUSTOM_TYPES_TOPIC,
          },
        ],
        fields: ['kind', 'metadata.name', 'metadata.namespace'],
      })
      .then(
        response => response.items.map(e => stringifyEntityRef(e)).sort() || [],
      );
  });

  const { error: flinkJobError, value: flinkJobs } = useAsync(async () => {
    return await catalogApi
      .getEntities({
        filter: [
          {
            kind: ['Component'],
            ['spec.type']: CHEETAH_CUSTOM_TYPES_FLINK,
          },
        ],
        fields: ['kind', 'metadata.name', 'metadata.namespace'],
      })
      .then(
        response => response.items.map(e => stringifyEntityRef(e)).sort() || [],
      );
  });

  useEffect(() => {
    if (topicsError) {
      alertApi.post({
        message: `Failed to load entity ${CHEETAH_CUSTOM_TYPES_TOPIC}`,
        severity: 'error',
      });
    }
    if (flinkJobError) {
      alertApi.post({
        message: `Failed to load entity ${CHEETAH_CUSTOM_TYPES_FLINK}`,
        severity: 'error',
      });
    }
  }, [topicsError, flinkJobError, alertApi]);

  const {
    rootEntityNames,
    setRootEntityNames,
    direction,
    setDirection,
    maxDepth,
    setMaxDepth,
    unidirectional,
    mergeRelations,
    setMergeRelations,
  } = useCheetahCatalogGraphPage({
    initialState: {
      direction: Direction.LEFT_RIGHT,
      maxDepth: 4,
      unidirectional: false,
      mergeRelations: true,
    },
  });

  function setRootEntityNamesWrapper(value: CompoundEntityRef) {
    setRootEntityNames([value]); // Right now we only allow 1 system to be selected
  }

  function CustomNodeWrapper({
    node: { id, entity, color = 'default', focused, onClick },
  }: DependencyGraphTypes.RenderNodeProps<EntityNode>) {
    const kind = entity.kind;
    const name = entity.metadata.name;
    const namespace = entity.metadata.namespace ?? '';

    const isKafkaTopic =
      kafkatopics?.includes(
        stringifyEntityRef({ kind: kind!, namespace, name }),
      ) || false;
    const isFlinkJob =
      flinkJobs?.includes(
        stringifyEntityRef({ kind: kind!, namespace, name }),
      ) || false;

    // eslint-disable-next-line new-cap
    return CustomNode(
      {
        node: {
          id,
          entity,
          name,
          namespace,
          color,
          focused,
          onClick,
        },
      },
      isKafkaTopic,
      isFlinkJob,
    );
  }

  if (flinkJobError || topicsError) {
    return <></>;
  }

  return (
    <Page themeId="home">
      <Header
        title="System Catalog Graph"
        // subtitle={rootEntityNames.map(e => humanizeEntityRef(e)).join(', ')}
        subtitle="Custom view with custom styles. Clicking on an entity will navigate to it"
      />
      <Content stretch className={classes.content}>
        <ContentHeader title="System overview">
          <SupportButton>
            Start tracking your component in by adding it to the software
            catalog.
          </SupportButton>
        </ContentHeader>
        <Grid container alignItems="stretch" className={classes.container}>
          {showFilters && (
            <Grid item xs={12} lg={2} className={classes.filters}>
              <SelectedSystemFilter
                value={rootEntityNames[0]}
                onChange={setRootEntityNamesWrapper}
              />
              <MaxDepthFilter value={maxDepth} onChange={setMaxDepth} />
              <DirectionFilter value={direction} onChange={setDirection} />
              {/* <SwitchFilter
                value={unidirectional}
                onChange={setUnidirectional}
                label="Simple"
              /> */}
              <SwitchFilter
                value={mergeRelations}
                onChange={setMergeRelations}
                label="Merge Relations"
              />
              <ExportToPlantuml
                rootEntityNames={rootEntityNames}
                maxDepth={maxDepth}
                kinds={selectedKinds}
                relations={selectedRelations}
                // unidirectional={unidirectional}
                mergeRelations={mergeRelations}
              />
            </Grid>
          )}
          <Grid item xs className={classes.fullHeight}>
            <Paper className={classes.graphWrapper}>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                className={classes.legend}
              >
                <ZoomOutMap className="icon" /> Use pinch &amp; zoom to move
                around the diagram. Click to change active node, shift click to
                navigate to entity.
              </Typography>
              <EntityRelationsGraph
                rootEntityNames={rootEntityNames}
                maxDepth={maxDepth}
                kinds={
                  selectedKinds && selectedKinds.length > 0
                    ? selectedKinds
                    : undefined
                }
                relations={
                  selectedRelations && selectedRelations.length > 0
                    ? selectedRelations
                    : undefined
                }
                mergeRelations={mergeRelations}
                unidirectional={unidirectional}
                renderLabel={CustomLabel}
                renderNode={CustomNodeWrapper}
                onNodeClick={onNodeClick}
                direction={direction}
                relationPairs={relationPairs}
                className={classes.graph}
                zoom="enabled"
                // curve={curve}
              />
            </Paper>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
